import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import CreatableSelect from 'react-select/creatable';
import { CONSTANT } from "../../../core/static-constant";
import { BookService } from "../../../services/book.service";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import { ActionMeta } from 'react-select';
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import { BotService } from "../../../services/bot.service";
import { UserHardwareSpecificService } from "../../../services/hardwareSpecific.service";
import Select from "react-select";
import { NuggetsTagService } from "../../../services/nuggetsTag.service";

type OptionType = {
    __isNew__: OptionType; label: string; value: string
};

const AddEditBook = () => {
    const { setBookData, bookData, setNuggetTagData, nuggetTagData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [MetricId, setMetricId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDeviceName, setSelectedDeviceName] = useState("");
    const [selectedDeviceId, setSelectedDeviceId] = useState<any>("");
    const [categoryId, setCategoryId] = useState("");

    const [selectedMetricName, setSelectedMetricName] = useState([]);
    const [selectedMetricId, setSelectedMetricId] = useState<any>([]);
    const [selectedMetricIdArr, setSelectedMetricIdArr] = useState<any>([]);

    const [MetricAllData, setMetricAllData] = useState<any>({
        bookId: "",
        bookTitle: "",
        author1: "",
        author2: "",
        shortDesc: "",
        longDesc: "",
        coverUrl:"",
        referralLink: "",
        price: "",
        relatedBooks: "", //need to think
        rating: "",
        userFeedback: "",
        endorseBy: "",
        crossSellCallout: "",
        keyTakeaways: [],
        userReview: [],
        primaryTag: "",
        category: ""
    });

    const validationSchema = yup.object().shape({
        bookTitle: yup.string().required("required"),
        bookId: yup.string().required("required"),
        // keyTakeaways: yup.array().required("required"),
        shortDesc: yup.string().required("required"),
        longDesc: yup.string().required("required"),
        coverUrl: yup.string().required("required"),
        primaryTag: yup.string().required("required"),
        category: yup.string().required("required"),
    });

    const filteredOptions = bookData?.filter((assessmentQuestionName: any) => {
        return assessmentQuestionName._id !== MetricId;
    });

    useEffect(() => {
            const categoryIDs = nuggetTagData.filter((ele: any) => ele.tagName === selectedDeviceName).map((ele: any) => ele.categoryId);
            const categoryIDString = categoryIDs.length > 0 ? categoryIDs[0] : '';
            setCategoryId(categoryIDString);
        }, [selectedDeviceName]);

        const getAllBookName = async () => {
                try {
                  let data: any = await BookService.getAllBookName();
                  if (data && data.status === CONSTANT.SUCCESS) {
                    const responseData = data?.data?.book;
                    setBookData(responseData);
                  } else {
                    toast.error(data.message);
                  }
                } catch (error: any) {
                    toast.error(error?.response?.data?.message);
                }
              };
        
              useEffect(() => {
                const data = selectedMetricName
                    ? selectedMetricName.map((item: any) => ({
                        value: item.id,
                        label: item.label,
                    }))
                    : [];
        
                setSelectedMetricId(data);
            }, [selectedMetricName]);

    const getAllNuggetTagName = async () => {
            try {
                const requestBody = {
                    tagLevel: CONSTANT.NUGGET_TAG.PRIMARY_TAG,
                    tagType: CONSTANT.TAG_LEVEL.CONTEXT_TAG
                };
                let data = await NuggetsTagService.getAllNuggetTagByType(requestBody);
                if (data && data.status === CONSTANT.SUCCESS) {
                    const responseData = data?.data?.nuggetTag;
                    setNuggetTagData(responseData);
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.message);
            }
        };

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const { values, handleChange, handleSubmit, setValues, setFieldValue } =
        useFormik({
            initialValues: { ...MetricAllData },
            validationSchema,
            onSubmit: async (values, action) => {
                try {
                    // if (!values.metricWhatWhy || values.metricWhatWhy.length === 0) {
                    //     toast.error("Options are required.");
                    //     setIsLoading(false);
                    //     return;
                    // }
                    // for (const option of values.metricWhatWhy) {
                    //     if (!option.what || !option.why) {
                    //         toast.error("Options are required.");
                    //         setIsLoading(false);
                    //         return;
                    //     }
                    // }
                    const filteredKeyTakeaways = MetricAllData.keyTakeaways.filter((prompt: any) => prompt !== '');
                    const filteredUserReviews = MetricAllData.userReview.filter((prompt: any) => prompt !== '');
                    const filteredValues: any = { ...values,primaryTag: selectedDeviceId, category: categoryId, relatedBooks: selectedMetricIdArr, keyTakeaways: filteredKeyTakeaways, userReview: filteredUserReviews};
                    // filteredValues.fieldName = filteredValues.fieldName.map((option: any) => ({
                    //     label: option.label,
                    //     value: option.value,
                    // }));
                    setIsLoading(true);
                    const data = MetricId ? await BookService.updateBookById(MetricId, filteredValues) : await BookService.createBook(filteredValues);
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        action.resetForm();
                        const MetricName = await BookService.getAllBookName();
                        setBookData(MetricName?.data?.book);
                        navigate(ROUTING_CONSTANT.BOOK_VIEW);

                        const { _id, name } = data.data;

                        if (MetricId) {
                            const existingIndex = bookData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setBookData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, name };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

        const handleAddKeyTakeaways = () => {
            if (MetricAllData.keyTakeaways.length === 0 || MetricAllData.keyTakeaways[MetricAllData.keyTakeaways.length - 1] !== '') {
                setMetricAllData({
                    ...MetricAllData,
                    keyTakeaways: [...MetricAllData.keyTakeaways, ''],
                });
            }
        };
    
        const handleRemoveKeyTakeaways = (index: any) => {
            const updatedChatPrompts = MetricAllData.keyTakeaways.filter((_: any, i: any) => i !== index).filter((prompt: any) => prompt !== '');
            setMetricAllData({
                ...MetricAllData,
                keyTakeaways: updatedChatPrompts,
            });
        };
    
        const handleKeyTakeawaysChange = (index: any, event: any) => {
            const newChatPrompts = MetricAllData.keyTakeaways.map((prompt: any, i: any) =>
                i === index ? event.target.value : prompt
            ).filter((prompt: any) => prompt !== '');
            setMetricAllData({
                ...MetricAllData,
                keyTakeaways: newChatPrompts,
            });
        };

        const handleAddUserReview = () => {
            if (MetricAllData.userReview.length === 0 || MetricAllData.userReview[MetricAllData.userReview.length - 1] !== '') {
                setMetricAllData({
                    ...MetricAllData,
                    userReview: [...MetricAllData.userReview, ''],
                });
            }
        };
    
        const handleRemoveUserReview = (index: any) => {
            const updatedChatPrompts = MetricAllData.userReview.filter((_: any, i: any) => i !== index).filter((prompt: any) => prompt !== '');
            setMetricAllData({
                ...MetricAllData,
                userReview: updatedChatPrompts,
            });
        };
    
        const handleUserReviewChange = (index: any, event: any) => {
            const newChatPrompts = MetricAllData.userReview.map((prompt: any, i: any) =>
                i === index ? event.target.value : prompt
            ).filter((prompt: any) => prompt !== '');
            setMetricAllData({
                ...MetricAllData,
                userReview: newChatPrompts,
            });
        };

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setMetricId(decryptData(params.id));
                getBookById(decryptData(params.id));
                // setFieldsDisabled(true);
            }
        };
        init();
        getAllNuggetTagName();
        getAllBookName();
    }, []);

    const getBookById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await BookService.getBookById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setMetricAllData(response?.data?.book);
                setValues({ ...response?.data?.book });
                setSelectedDeviceName(response?.data?.book?.primaryTag?.tagName);
                setSelectedDeviceId(response?.data?.book?.primaryTag?._id);
                setCategoryId(response?.data?.book?.category?._id);

                const selectedMetricNamesss = response?.data?.book?.relatedBooks.map(
                    (metric: any) => ({
                        id: metric._id,
                        label: metric.bookTitle,
                    })
                );
                setSelectedMetricName(selectedMetricNamesss);

                const relatedBooks = response?.data?.book?.relatedBooks.map((metric: any) => metric._id);
                setSelectedMetricIdArr(relatedBooks);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.BOOK_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {MetricId ? CONSTANT.UPDATE : CONSTANT.ADD} Book
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                        <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Book Id*</label>

                                <FormGroup id="bookId">
                                    <Input
                                        placeholder="Book Id"
                                        className={`${isInvalidForm && !values.bookId
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="bookId"
                                        value={values.bookId}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.bookId && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Book Title*</label>

                                <FormGroup id="bookTitle">
                                    <Input
                                        placeholder="Book Title"
                                        className={`${isInvalidForm && !values.bookTitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="bookTitle"
                                        value={values.bookTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.bookTitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Author 1*</label>

                                <FormGroup id="author1">
                                    <Input
                                        placeholder="Author 1"
                                        className={`${isInvalidForm && !values.author1
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="author1"
                                        value={values.author1}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.author1 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Author 2*</label>

                                <FormGroup id="author2">
                                    <Input
                                        placeholder="Author 2"
                                        className={`${isInvalidForm && !values.author2
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="author2"
                                        value={values.author2}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {/* {isInvalidForm && !values.author2 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )} */}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Short Desc*</label>

                                <FormGroup id="shortDesc">
                                    <Input
                                        placeholder="Short Desc"
                                        className={`${isInvalidForm && !values.shortDesc
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="shortDesc"
                                        value={values.shortDesc}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.shortDesc && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Long Desc*</label>

                                <FormGroup id="longDesc">
                                    <Input
                                        placeholder="Long Desc"
                                        className={`${isInvalidForm && !values.longDesc
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="longDesc"
                                        value={values.longDesc}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.longDesc && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Cover Url*</label>

                                <FormGroup id="coverUrl">
                                    <Input
                                        placeholder="Cover Url"
                                        className={`${isInvalidForm && !values.coverUrl
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="coverUrl"
                                        value={values.coverUrl}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.coverUrl && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Referral Link*</label>

                                <FormGroup id="referralLink">
                                    <Input
                                        placeholder="Referral Link"
                                        className={`${isInvalidForm && !values.referralLink
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="referralLink"
                                        value={values.referralLink}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.referralLink && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Price*</label>

                                <FormGroup id="price">
                                    <Input
                                        placeholder="Price"
                                        className={`${isInvalidForm && !values.price
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="price"
                                        value={values.price}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.price && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Rating*</label>

                                <FormGroup id="rating">
                                    <Input
                                        placeholder="Rating"
                                        className={`${isInvalidForm && !values.rating
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="rating"
                                        value={values.rating}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.rating && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>


                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">User Feedback*</label>

                                <FormGroup id="userFeedback">
                                    <Input
                                        placeholder="User Feedback"
                                        className={`${isInvalidForm && !values.userFeedback
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="userFeedback"
                                        value={values.userFeedback}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.userFeedback && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>


                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Endorse By*</label>

                                <FormGroup id="endorseBy">
                                    <Input
                                        placeholder="Endorse By"
                                        className={`${isInvalidForm && !values.endorseBy
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="endorseBy"
                                        value={values.endorseBy}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.endorseBy && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Cross Sell Call out*</label>

                                <FormGroup id="crossSellCallout">
                                    <Input
                                        placeholder="Cross Sell Call out"
                                        className={`${isInvalidForm && !values.crossSellCallout
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="crossSellCallout"
                                        value={values.crossSellCallout}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.crossSellCallout && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                    <FormGroup id="options">
                                        <div>
                                            <>
                                                <label className="form-labels">Key Takeaways</label>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-plus-circle sku-icon cursor-pointer"
                                                    viewBox="0 0 16 16"
                                                    onClick={handleAddKeyTakeaways}
                                                >
                                                    <path
                                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path
                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                </svg>
                                            </>
                                        </div>
                                        <div>
                                            {MetricAllData?.keyTakeaways.map((prompt: any, index: any) => (
                                                <div key={index} className="d-flex align-items-center justify-content-center mt-1">
                                                    <Input
                                                        type="text"
                                                        value={prompt}
                                                        placeholder="Key Takeaways"
                                                        onChange={(event) => handleKeyTakeawaysChange(index, event)}
                                                        className={`${isInvalidForm && !values.keyTakeaways
                                                            ? "border input-form"
                                                            : "input-form"
                                                            }`}
                                                    />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer mt-1"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveKeyTakeaways(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            ))}
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <FormGroup id="options">
                                        <div>
                                            <>
                                                <label className="form-labels">User Review</label>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-plus-circle sku-icon cursor-pointer"
                                                    viewBox="0 0 16 16"
                                                    onClick={handleAddUserReview}
                                                >
                                                    <path
                                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path
                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                </svg>
                                            </>
                                        </div>
                                        <div>
                                            {MetricAllData?.userReview.map((prompt: any, index: any) => (
                                                <div key={index} className="d-flex align-items-center justify-content-center mt-1">
                                                    <Input
                                                        type="text"
                                                        value={prompt}
                                                        placeholder="User Review"
                                                        onChange={(event) => handleUserReviewChange(index, event)}
                                                        className={`${isInvalidForm && !values.userReview
                                                            ? "border input-form"
                                                            : "input-form"
                                                            }`}
                                                    />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer mt-1"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveUserReview(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            ))}
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-12 col-md-6" key="nuggets">
                                <label className="form-labels">Select Primary Tag*</label>
                                <FormGroup id="device">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            setSelectedDeviceId(
                                                selectedOption ? selectedOption.value : ""
                                            );
                                            setSelectedDeviceName(
                                                selectedOption ? selectedOption.label : ""
                                            );
                                        }}
                                        options={
                                            nuggetTagData &&
                                            nuggetTagData
                                                .map((device: any) => ({
                                                    value: device._id,
                                                    label: device.tagName,
                                                }))
                                        }
                                        placeholder="Select Primary Tag"
                                        value={
                                            selectedDeviceName
                                                ? {
                                                    value: selectedDeviceId,
                                                    label: selectedDeviceName,
                                                }
                                                : null
                                        }
                                        isSearchable={true}
                                        className={`${isInvalidForm && !selectedDeviceName
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                    />
                                </FormGroup>
                                {isInvalidForm && selectedDeviceId.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6" key="metric">
                                <label className="form-labels">Select Books*</label>

                                <FormGroup id="metric">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOptions: any) => {
                                            const metric = selectedOptions.map((metrics: any) => ({
                                                id: metrics.value,
                                                label: metrics.label,
                                            }));

                                            const metrics = selectedOptions.map((metric: any) => metric.value);
                                            setSelectedMetricIdArr(metrics);

                                            setSelectedMetricName(metric);
                                        }}
                                        options={
                                            filteredOptions &&
                                            filteredOptions
                                                .map((metric: any) => ({
                                                    value: metric._id,
                                                    label: metric.bookTitle,
                                                }))
                                        }
                                        placeholder="Select Books"
                                        value={selectedMetricId}
                                        isSearchable={true}
                                        isMulti
                                        className={`${isInvalidForm && selectedMetricId.length === 0
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                    />
                                </FormGroup>
                                {/* {isInvalidForm && selectedMetricId.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.METRIC_ID}</small>
                                    </p>
                                )} */}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 mt-5 process-btn ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {MetricId ? CONSTANT.UPDATE : CONSTANT.ADD} Book
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditBook;
