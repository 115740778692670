import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { ProductService, RecommendationRuleService } from "../../../services";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import { Checkbox } from "@material-ui/core";

const AddEditProduct = () => {
    const {
        productData, setProductData
    }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [ProductId, setProductId] = useState("");
    const [isInvalidForm, setIsInvalidForm] = useState<any>(false);
    const [isLoading, setIsLoading] = useState(false);

    const [productAllData, setProductAllData] = useState<any>({
        productName: "",
        productDesc: "",
        productType: "",
        supplyDuration: "",
        whenToTakeSentence: "",
        whenToTakeOneWord: "",
        // productImage: [{ url: "", isPrimary: false }],
        productImage: "",
        productPrice: "",
        productDossage: "",
        packSize: "",
        keyIngredients: [{ name: "", description: "" }],
        howToTake: "",
        howToWork: "",
        courseLength: "",
        brandName: "",
        whyProduct: {
            title: '',
            list: [],
        },
        productFaqs: [{ question: "", answer: "" }],
        chatPrompts: [],
    });

    const validationSchema = yup.object().shape({
        productName: yup.string().required("required"),
        productDesc: yup.string().required("required"),
        productType: yup.string().required("required"),
        supplyDuration: yup.string().required("required"),
        whenToTakeSentence: yup.string().required("required"),
        whenToTakeOneWord: yup.string().required("required"),
        productPrice: yup.string().required("required"),
        productDossage: yup.string().required("required"),
        howToTake: yup.string().required("required"),
        packSize: yup.string().required("required"),
        brandName: yup.string().required("required"),
        keyIngredients: yup.string().required("required"),
        // productImage: yup
        //     .string()
        //     .matches(isValidUrl(), "required")
        //     .required("required"),
    });

    const handleTitleChange = (e: any) => {
        setProductAllData((prevData: any) => ({
            ...prevData,
            whyProduct: {
                ...prevData.whyProduct,
                title: e.target.value
            }
        }));
    };

    const handleAddItem = () => {
        setProductAllData((prevData: any) => ({
            ...prevData,
            whyProduct: {
                ...prevData.whyProduct,
                list: [...prevData.whyProduct.list, { short: '', long: '' }]
            }
        }));
    };

    const handleRemoveItem = (index: any) => {
        setProductAllData((prevData: any) => ({
            ...prevData,
            whyProduct: {
                ...prevData.whyProduct,
                list: prevData.whyProduct.list.filter((_: any, i: any) => i !== index)
            }
        }));
    };

    const handleAddIngredients = () => {
            const newOption = { name: "", description: "" };
            if (values.keyIngredients.every((option: any) => option.name !== "" && option.description !== "")) {
                setValues((prevValues: any) => ({
                    ...prevValues,
                    keyIngredients: [...prevValues.keyIngredients, newOption],
                }));
            } else {
                toast.error(CONSTANT.VALID_OPTIONS);
            }
        };
    
    const handleRemoveIngredients = (index: any) => {
        setValues((prevValues: any) => {
            const updatedOptions = [...prevValues.keyIngredients];
            updatedOptions.splice(index, 1);
            return { ...prevValues, keyIngredients: updatedOptions };
        });
    };

    const handleItemChange = (index: any, field: any, value: any) => {
        setProductAllData((prevData: any) => ({
            ...prevData,
            whyProduct: {
                ...prevData.whyProduct,
                list: prevData.whyProduct.list.map((item: any, i: any) =>
                    i === index ? { ...item, [field]: value } : item
                )
            }
        }));
    };


    const handleAddProduct = () => {
        const allFieldsFilled = values.productFaqs.every((option: any) =>
            option.question !== "" && option.answer !== ""
        );
        if (allFieldsFilled) {
            const newOption = { question: "", answer: "" };
            setValues((prevValues: any) => ({
                ...prevValues,
                productFaqs: [...prevValues.productFaqs, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };
    
    const handleRemoveProduct = (index: any) => {
        setValues((prevValues: any) => {
            const updatedOptions = [...prevValues.productFaqs];
            updatedOptions.splice(index, 1);
            return { ...prevValues, productFaqs: updatedOptions };
        });
    };
    
    const handleAddChatPrompt = () => {
        if (productAllData.chatPrompts.length === 0 || productAllData.chatPrompts[productAllData.chatPrompts.length - 1] !== '') {
            setProductAllData({
                ...productAllData,
                chatPrompts: [...productAllData.chatPrompts, ''],
            });
        }
    };

    const handleRemoveChatPrompt = (index: any) => {
        const updatedChatPrompts = productAllData.chatPrompts.filter((_: any, i: any) => i !== index).filter((prompt: any) => prompt !== '');
        setProductAllData({
            ...productAllData,
            chatPrompts: updatedChatPrompts,
        });
    };

    const handleChatPromptChange = (index: any, event: any) => {
        const newChatPrompts = productAllData.chatPrompts.map((prompt: any, i: any) =>
            i === index ? event.target.value : prompt
        ).filter((prompt: any) => prompt !== '');
        setProductAllData({
            ...productAllData,
            chatPrompts: newChatPrompts,
        });
    };

    const { values, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...productAllData },
        // validationSchema,

        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const filteredChatPrompts = productAllData.chatPrompts.filter((prompt: any) => prompt !== '');
                const filteredIngredients = values.keyIngredients.filter((option: any) => option.name !== "" && option.description !== "");
                const filteredOptions = values.productFaqs.filter((option: any) => option.question !== "" && option.answer !== "");
                const updatedProductImage = [...productAllData.productImage];
                const isPrimarySelected = updatedProductImage.some(image => image.isPrimary);
                // if (!isPrimarySelected && updatedProductImage.length > 0) {
                //     updatedProductImage[0].isPrimary = true;
                // }
                // setProductAllData((prevState: any) => ({
                //     ...prevState,
                //     // productImage: updatedProductImage,
                // }));
                const payload = {
                    ...values,
                    productFaqs: filteredOptions,
                    // productImage: updatedProductImage,
                    whyProduct: productAllData.whyProduct,
                    keyIngredients: filteredIngredients,
                    chatPrompts: filteredChatPrompts
                };
                const data = ProductId ? await ProductService.updateProductById(ProductId, payload) : await ProductService.addProduct(payload);
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.PRODUCT_VIEW);
                    const RecipeName = await ProductService.getAllProductName();
                    setProductData(RecipeName?.data?.product);

                    const { _id, productName } = data.data;
                    if (ProductId) {
                        const existingIndex = productData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setProductData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, productName };
                                return newData;
                            });
                        }
                    }
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                console.error(error);
                toast.error(error.response.data.error);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setProductId(decryptData(params.id));
                getProductById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const getProductById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await ProductService.getProductById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {

                setProductAllData(response?.data?.product);
                setValues({ ...response?.data?.product });
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
            toast.error(error.response.data.error);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.PRODUCT_VIEW);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const handleCheckboxPreviewChange = (index: any) => {
        const newProductImage = productAllData.productImage.map((image: any, i: any) => ({
            ...image,
            isPrimary: i === index,
        }));
        setProductAllData({ ...productAllData, productImage: newProductImage });
    };

    const handleAddRecommendationsShortLong = () => {
        const newOption = { url: "", isPrimary: false };
        const allOptionsValid = productAllData.productImage.every((option: any) => option.url !== "");
        if (allOptionsValid) {
            setProductAllData((prevValues: any) => ({
                ...prevValues,
                productImage: [...prevValues.productImage, newOption],
            }));
        } else {
            console.error("All options must have a URL before adding a new one.");
        }
    };

    const handleRemoveRecommendationsShortLong = (index: any) => {
        setProductAllData((prevValues: any) => {
            const updatedOptions = [...prevValues.productImage];
            updatedOptions.splice(index, 1);
            return { ...prevValues, productImage: updatedOptions };
        });
    };

    const handleChangeURL = (e: any) => {
        const { name, value } = e.target;
        const index = name.match(/\d+/)[0];
        const newProductImage = [...productAllData.productImage];
        newProductImage[index].url = value;
        setProductAllData({ ...productAllData, productImage: newProductImage });
    };

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {ProductId ? CONSTANT.UPDATE : CONSTANT.ADD} Product
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product Name*</label>

                                <FormGroup id="productName">
                                    <Input
                                        placeholder="Product Name"
                                        className={`${isInvalidForm && !values.productName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="productName"
                                        value={values.productName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.productName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product Description*</label>
                                <FormGroup id="productDesc">
                                    <Input
                                        placeholder="Product Description"
                                        className={`${isInvalidForm && !values.productDesc
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="productDesc"
                                        value={values.productDesc}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.productDesc && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product Type*</label>

                                <FormGroup id="productType">
                                    <Input
                                        placeholder="Product Type "
                                        className={`${isInvalidForm && !values.productType
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="productType"
                                        value={values.productType}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.productType && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TYPE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product Supply Duration*</label>

                                <FormGroup id="supplyDuration">
                                    <Input
                                        placeholder="Product Supply Duration"
                                        className={`${isInvalidForm && !values.supplyDuration
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="supplyDuration"
                                        value={values.supplyDuration}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.supplyDuration && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product When To Take(Sentence format)*</label>

                                <FormGroup id="whenToTakeSentence">
                                    <Input
                                        placeholder="Product When To Take(Sentence format)"
                                        className={`${isInvalidForm && !values.whenToTakeSentence
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="whenToTakeSentence"
                                        value={values.whenToTakeSentence}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.whenToTakeSentence && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product When To Take(One word format)*</label>

                                <FormGroup id="whenToTakeOneWord">
                                    <Input
                                        placeholder="Product When To Take(One word format)"
                                        className={`${isInvalidForm && !values.whenToTakeOneWord
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="whenToTakeOneWord"
                                        value={values.whenToTakeOneWord}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.whenToTakeOneWord && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                    <FormGroup id="options">
                                        <div>
                                            <>
                                                <label className="form-labels">Chat Prompts</label>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-plus-circle sku-icon cursor-pointer"
                                                    viewBox="0 0 16 16"
                                                    onClick={handleAddChatPrompt}
                                                >
                                                    <path
                                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path
                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                </svg>
                                            </>
                                        </div>
                                        <div>
                                            {productAllData?.chatPrompts.map((prompt: any, index: any) => (
                                                <div key={index} className="d-flex align-items-center justify-content-center mt-1">
                                                    <Input
                                                        type="text"
                                                        value={prompt}
                                                        placeholder="Chat Prompt"
                                                        onChange={(event) => handleChatPromptChange(index, event)}
                                                        className={`${isInvalidForm && !values.chatPrompts
                                                            ? "border input-form"
                                                            : "input-form"
                                                            }`}
                                                    />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer mt-1"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveChatPrompt(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            ))}
                                        </div>
                                    </FormGroup>
                                </div>

                            {/* <div className="col-sm-12 col-md-6">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <><label className="form-labels">Product Image URL</label><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddRecommendationsShortLong}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg></>
                                        </div>
                                    </>
                                    <div>
                                        {productAllData.productImage.map((option: any, index: any) => (
                                            <div key={index} className="">
                                                <div className="d-flex gap-2 align-items-center justify-content-center">
                                                    <Input
                                                        placeholder="Product Image URL"
                                                        className={option.url ? "input-form" : "border input-form"}
                                                        value={option.url}
                                                        name={`productImage[${index}].url`}
                                                        onChange={handleChangeURL}
                                                    />
                                                    <Checkbox
                                                        checked={option.isPrimary}
                                                        onChange={() => handleCheckboxPreviewChange(index)}
                                                    />
                                                    <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-dash-circle sku-icon cursor-pointer"
                                                    viewBox="0 0 16 16"
                                                    onClick={() => handleRemoveRecommendationsShortLong(index)}
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                            </div> */}
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product Image*</label>

                                <FormGroup id="productImage">
                                    <Input
                                        className={`${isInvalidForm && !values.productImage
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        placeholder="Product Image"
                                        name="productImage"
                                        value={values.productImage}
                                        // type="number"
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.productImage && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.PRICE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product Price*</label>

                                <FormGroup id="productPrice">
                                    <Input
                                        className={`${isInvalidForm && !values.productPrice
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        placeholder="Product Price"
                                        name="productPrice"
                                        value={values.productPrice}
                                        type="number"
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.productPrice && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.PRICE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product Dossage*</label>

                                <FormGroup id="productDossage">
                                    <Input
                                        placeholder="Product Dossage"
                                        className={`${isInvalidForm && !values.productDossage
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="productDossage"
                                        value={values.productDossage}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.productDossage && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product Pack Size*</label>

                                <FormGroup id="packSize">
                                    <Input
                                        placeholder="Product Pack Size"
                                        className={`${isInvalidForm && !values.packSize
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="packSize"
                                        value={values.packSize}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.packSize && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div><div className="col-sm-12 col-md-6">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <><label className="form-labels">Ingredients*</label><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddIngredients}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg></>
                                        </div>
                                    </>
                                    <div>
                                        {values?.keyIngredients?.map((option: any, index: any) => (
                                            <div key={index} className="">
                                                <div className="d-flex gap-2 align-items-center justify-content-center mt-1">
                                                    <Input
                                                        placeholder="Ingredients Name"
                                                        className={`${isInvalidForm && !option.name ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.name}
                                                        name={`keyIngredients[${index}].name`}
                                                        onChange={handleChange} />
                                                    <Input
                                                        placeholder="Ingredients Description"
                                                        className={`${isInvalidForm && !option.description ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.description}
                                                        name={`keyIngredients[${index}].description`}
                                                        onChange={handleChange} />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveIngredients(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                                {isInvalidForm && values.keyIngredients.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.OPTION}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product Brand Name*</label>

                                <FormGroup id="brandName">
                                    <Input
                                        className={`${isInvalidForm && !values.brandName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        placeholder="Product Brand Name"
                                        name="brandName"
                                        value={values.brandName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.brandName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product How To Take*</label>

                                <FormGroup id="howToTake">
                                    <Input
                                        placeholder="Product How To Take"
                                        className={`${isInvalidForm && !values.howToTake
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="howToTake"
                                        value={values.howToTake}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.howToTake && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product How To Work*</label>

                                <FormGroup id="howToWork">
                                    <Input
                                        placeholder="Product How To Work"
                                        className={`${isInvalidForm && !values.howToWork
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="howToWork"
                                        value={values.howToWork}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.howToWork && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product Course Length*</label>

                                <FormGroup id="courseLength">
                                    <Input
                                        placeholder="Product Course Length"
                                        className={`${isInvalidForm && !values.courseLength
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseLength"
                                        value={values.courseLength}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseLength && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-12">
                                <FormGroup id="options">
                                    <label className="form-labels">Product Why*</label>
                                    <div>
                                        <div className="d-flex col-md-12 justify-content-center align-items-center">
                                            <Input
                                                type="text"
                                                value={productAllData.whyProduct.title}
                                                onChange={handleTitleChange}
                                                placeholder="Title"
                                                className="input-form"
                                            /><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddItem}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg>
                                        </div>

                                        {productAllData.whyProduct.list.map((item: any, index: any) => (
                                            <div key={index} className="d-flex gap-2 col-md-11 mt-2 justify-content-center align-items-center">
                                                <h4>{index + 1}.</h4>
                                                <Input
                                                    type="text"
                                                    value={item.short}
                                                    onChange={(e: any) => handleItemChange(index, 'short', e.target.value)}
                                                    placeholder="Product Why Short"
                                                    className="input-form"
                                                />
                                                <Input
                                                    type="text"
                                                    value={item.long}
                                                    onChange={(e: any) => handleItemChange(index, 'long', e.target.value)}
                                                    placeholder="Product Why Long"
                                                    className="input-form"
                                                />
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-dash-circle sku-icon cursor-pointer"
                                                    viewBox="0 0 16 16"
                                                    onClick={() => handleRemoveItem(index)}
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                </svg>
                                            </div>
                                        ))}
                                    </div>

                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-12">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <><label className="form-labels">Product Faqs</label><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddProduct}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg></>
                                        </div>
                                    </>
                                    <div>
                                        {values?.productFaqs?.map((option: any, index: any) => (
                                            <div key={index} className="">
                                                <div className="d-flex gap-2 align-items-center justify-content-center mt-3">
                                                    <Input
                                                        placeholder="Product Faq Question"
                                                        className={`${isInvalidForm && !option.question ? "border input-form" : "input-form"}`}
                                                        value={option.question}
                                                        name={`productFaqs[${index}].question`}
                                                        onChange={handleChange} />
                                                    <Input
                                                        placeholder="Product Faq Answer"
                                                        className={`${isInvalidForm && !option.answer ? "border input-form" : "input-form"}`}
                                                        value={option.answer}
                                                        name={`productFaqs[${index}].answer`}
                                                        onChange={handleChange} />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveProduct(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </FormGroup>
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={() => setIsInvalidForm(true)}
                                isDisable={isLoading}
                                className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                    }`}
                            >
                                {ProductId ? CONSTANT.UPDATE : CONSTANT.ADD} Product
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditProduct;
