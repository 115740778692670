import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { InventoryService } from "../../../services/inventory.service";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    isValidHttpUrl,
    isValidUrl,
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import { decryptData } from "../../../core/auth.service";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import "../User/user.scss";
import Select from "react-select";
import { VariantsService } from "../../../services/variants.service";

const AddEditInventory = () => {
    const { variantsData, inventoryData, setInventoryData,setVariantsData }: any =
        useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [variantId, setUserSubCategoryId] = useState("");
    const [UserProfileSubCategoryAllData, setUserProfileSubCategoryAllData] = useState({
        quantity: "",
        variantId: "",
    });

    const [selectedCategoryName, setSelectedCategoryName] = useState("");
    const [selectedUserProfileSubCategoryId, setSelectedUserProfileSubCategoryId] =
        useState("");

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const { values, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...UserProfileSubCategoryAllData },
        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const data = variantId ? await InventoryService.updateInventoryById(variantId, { ...values,variantId:selectedUserProfileSubCategoryId  }) : await InventoryService.createInventory({ ...values,variantId:selectedUserProfileSubCategoryId });
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.INVENTORY_VIEW);

                    const { _id, quantity } = data.data;
                    if (variantId) {
                        const existingIndex = inventoryData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setInventoryData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, quantity };
                                return newData;
                            });
                        }
                    }
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.error);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setUserSubCategoryId(decryptData(params.id));
                getInventoryById(decryptData(params.id));
            }
        };
        init();
        if(variantsData?.length === 0){
            getAllVariantsName();
        }
    }, []);

    const getAllVariantsName = async () => {
        try {
          let data: any = await VariantsService.getAllVariantsName();
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.Variants;
            setVariantsData(responseData);
          } else {
            toast.error(data.message);
          }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
      };

    const getInventoryById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await InventoryService.getInventoryById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setUserProfileSubCategoryAllData(response?.data?.Inventory);
                setValues({ ...response?.data?.Inventory });
                setSelectedCategoryName(
                    `${response?.data?.Inventory?.variantId?.color} - ${response?.data?.Inventory?.variantId?.size} - ${response?.data?.Inventory?.variantId?.productId?.deviceName}`
                );
                setSelectedUserProfileSubCategoryId(response?.data?.Inventory?.variantId?._id);
                
            }
        } catch (error: any) {
            toast.error(error.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.INVENTORY_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    
    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {variantId ? CONSTANT.UPDATE : CONSTANT.ADD} Inventory
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Select Variant*</label>

                                <FormGroup id="categoryName">
                                     <Select
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            setSelectedUserProfileSubCategoryId(
                                                selectedOption ? selectedOption.value : ""
                                            );
                                            setSelectedCategoryName(
                                                selectedOption ? selectedOption.label : ""
                                            );
                                        }}
                                        options={
                                            variantsData &&
                                            variantsData.map((dosageAssistance: any) => ({
                                                value: dosageAssistance._id,
                                                label: `${dosageAssistance.color} - ${dosageAssistance.size} - ${dosageAssistance?.productId?.deviceName}`,
                                            }))
                                        }
                                        placeholder="Select Variant"
                                        value={
                                            selectedCategoryName
                                                ? {
                                                    value: selectedUserProfileSubCategoryId,
                                                    label: selectedCategoryName,
                                                }
                                                : null
                                        }
                                        className={`${isInvalidForm && !selectedCategoryName
                                                ? "border border-danger"
                                                : "border rounded-circle"
                                            }`}
                                        isSearchable={true}
                                    /> 
                                </FormGroup>
                                {isInvalidForm && selectedCategoryName.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.CATEGORY_ID}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Inventory Quantity*</label>

                                <FormGroup id="quantity">
                                    <Input
                                        placeholder="Inventory Quantity"
                                        className={`${isInvalidForm && !values.quantity
                                                ? "border border-danger input-form"
                                                : "input-form"
                                            }`}
                                        name="quantity"
                                        value={values.quantity}
                                        onChange={handleChange}
                                        type="number"
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.quantity && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 mt-5 process-btn ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {variantId ? CONSTANT.UPDATE : CONSTANT.ADD} Inventory
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditInventory;
