import httpCommon from "../core/http-common";
import { API_URL } from "../core/constant";
import { logoutUser } from "../core/auth.service";
import { toast } from "react-toastify";
import { CONSTANT } from "../core/static-constant";
import HttpMultipartCommonCore from "../core/HttpMultipartCommonCore";

export const BookService = {
    updateBookById,
    createBook,
    getBookById,
    getAllBook,
    deleteBookById,
    getAllBookName,
    uploadBookSheet
};

function createBook(data: any) {
    return httpCommon
        .post(API_URL.CREATE_BOOK, data)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function updateBookById(id: any, data: any) {
    return httpCommon
        .post(API_URL.UPDATE_BOOK_BY_ID + id, data)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function getBookById(id: any) {
    return httpCommon
        .get(API_URL.GET_BOOK_BY_ID + id)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function getAllBook(data: any) {
    let url = API_URL.GET_ALL_BOOK;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function deleteBookById(id: any) {
    return httpCommon
        .get(API_URL.DELETE_BOOK_BY_ID + id)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function getAllBookName() {
    return httpCommon
        .get(API_URL.GET_ALL_BOOK_NAME)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function uploadBookSheet(data: any) {
    let url = API_URL.UPLOAD_BOOK_SHEET;
    return HttpMultipartCommonCore.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}
