export const CONSTANT = Object.freeze({
  SUCCESS: "success",
  FAIL: "fail",
  ERROR: "error",
  FALSE: "false",
  TRUE: "true",
  UNDEFINED: "undefined",
  ADD: "Add",
  PDF: "Pdf",
  UPDATE: "Update",
  ACTIVE: "Active",
  DEACTIVE: "Deactive",
  DESC: "desc",
  ASC: "asc",
  YES: "Yes",
  NO: "No",
  SET: "set",
  GET: "get",
  REMOVE: "remove",
  GOAL_STATUS: "Please Select Goal Status",
  ERROR_OCCURRED: "Something went error",
  DATE_FORMATE: "DD-MMM-YYYY",
  DATE_FORMATE1: "YYYY-MM-DD",
  USER_DATA: "userData",
  USER_PROFILE: "userProfile",
  DARK_MODE_STATUS: "darkModeStatus",
  ASIDE_STATUS: "asideStatus",
  FACIT_ASIDE_DOC_STATUS: "facit_asideDocStatus",
  METRIC_SPECIFIC_USER_ID: "metricSpecificUserId",
  MEDICAL_SPECIFIC_USER_ID: "medicalSpecificUserId",
  HARDWARE_SPECIFIC_USER_ID: "hardwareSpecificUserId",
  USER_TABLE_SEARCH_KEYWORD: "userTableSearchKeyword",
  IMG_PATH: {
    SUB_CATEGORY: "uploaded_attachment/category/subCategory_images/",
    CATEGORY: "uploaded_attachment/category/category_images/",
    BRAND: "uploaded_attachment/brand/brand_images/",
  },
  STATUS_LIST: [
    { id: "Active", value: "Active" },
    { id: "Deactive", value: "Deactive" },
  ],
  YESNO:'YESNO',
  DROPDOWN:'DROPDOWN',
  MULTIOPTIONS:'MULTIOPTIONS',
  PLACEHOLDER:'PLACEHOLDER',
  AVATAR_REQUIRED_FIELD: [
    "avatarName",
    "avatarShortDescripton",
    "avatarLongDescription",
    "avatarThumbImage",
    "avatarGender",
    "avatarCoverImage",
    "avatar3DLink",
    "avatarPersonaName",
  ],
  BRAND_REQUIRED_FIELD: [
    "brandName",
    "brandDescription",
    "brandLogoUrl",
    "pointDiscountLimit",
    "brandType",
    "categoryId",
    "subCategoryId",
  ],
  CATEGORY_REQUIRED_FIELD: ["categoryName", "categoryGender"],
  CUSTOMIZATION_ITEM_REQUIRED_FIELD: [
    "product",
    "customizationPoint",
    "itemName",
    "thumbnail",
  ],
  CUSTOMIZATION_POINT_REQUIRED_FIELD: ["customizationName", "shortDescription"],
  MAPPING_REQUIRED_FIELD: ["avatarCode", "productCode", "cp3DLink"],
  PRODUCT_CODE_REQUIRED_FIELD: [
    "productName",
    "brand",
    "category",
    "productType",
    "productSubcategory",
  ],
  SUB_CATEGORY_REQUIRED_FIELD: ["category", "subCatgeoryName"],
  PINCODE_REQUIRED_FIELD: ["pincode", "country", "status"],
  EVENT_REQUIRED_FIELD: ["eventName", "eventPointEarn"],
  PRODUCT_CODE_INVENTORY_REQUIRED_FIELD: ["product", "size", "qty"],
  PROMO_CODE_REQUIRED_FIELD: ["code", "startDate", "endDate"],
  AUTH_TOKEN: "authToken",
  NAME: "Name is required",
  FIELD_NAME: "Field Name is required",
  DATA_RANGE: "Data Range is required",
  FREQUENCY: "Frequency is required",
  COUNT: "Count is required",
  TITLE: "Title is required",
  TYPE: "Type is required",
  AGE: "Age is required",
  GENDER: "Gender is required",
  FOLLOW_UP_ANSWER: "Follow up answer required",
  FOLLOW_UP_QUESTION: "Follow up question required",
  OPTION: "Options is required",
  FORMATE: "Formate is required",
  LONG_DESCRIPTION: "Long Description is required",
  SHORT_DESCRIPTION: "Short Description is required",
  SUB_TITLE: "Sub Title is required",
  CARD_TITLE: "Card Title is required",
  DESCRIPTION: "Descripton is required",
  IMAGE: "Image Url is required",
  IMAGE_VALID: "Please Enter Valid Image Url",
  WEIGHT: "Value of weight is required",
  QUANTITY: "Value of quantity is required",
  ADVICE: "Value of advice is required",
  SUBSCRIBE_AMOUNT: "Subscribe amount is required",
  SINGLE_AMOUNT: "Single amount is required ",
  INTRO: "Intro is required",
  HELP: "Please enter how product is works",
  PRICE: "Value of price is required",
  REQUIRED: "Field is required",
  ICON: "Please enter valid url for icon",
  BENEFITS: "Please enter Benefits",
  PRODUCT_ID: "Please select product name",
  CHAPTER_ID: "Please select chapter name",
  KEYWORD_ID: "Please select keyword name",
  CONTENT:"Enter valid content",
  MARKETING_ID: "Please select marketing  name",
  DOSAGE_ASSISTANCE_ID: "Please select dosage assistance name",
  INGREDIENT_ID: "Please select ingredient name",
  CATEGORY_ID: "Please select category name",
  MODEL_ID: "Please select Model name",
  SUB_CATEGORY_ID: "Please select sub category name",
  FAQ_ID: "Please select faq name",
  RECOMMENDATION_ID: "Please select Recommendation Rule name",
  RECIPE_ID: "Please select Recipe name",
  TRAINER_ID: "Please select trainer name",
  EXPERT_ID: "Please select Expert",
  CLASS_ID: "Please select class name",
  BENEFIT_ID: "Please select benefit name",
  METRIC_ID: "Please select Metric name",
  RECIPE_BUNDLE_ID: "Please select Recipe Bundle name",
  DEVICE_ID: "Please select Device name",
  SKU_ID: "Please select sku variant ",
  GOAL_ID: "Please select Goal",
  HOW_ITS_WORK: "Please enter how to use product",
  DELETE_CONFIRMATION: "Are you sure to delete record?",
  LOGOUT_CONFIRMATION: "Are you sure you want to logout?",
  TYPE_CHANGE_CONFIRMATION:"Are you sure to change type? Your changes will be discarded!",
  AUTHENTICATION_FAIL: "Authentication token fail..!!",
  EMAIL: "Email is required",
  PASSWORD: "Password is required ",
  FIRST_NAME: "First name is required",
  LAST_NAME: "Last name is required",
  LONG_TITLE: "Long Title is required",
  SHORT_TITLE: "Short Title is required",
  CONTACT_NUMBER: "Contact number is required",
  VALID_IMAGE: "Please enter valid image url",
  VALID_OPTIONS: "Options are Required",
  FILL_OPTIONS: "Please fill these are fields",
  VALID_CONVERSATION: "Please fill user message and bot response",
  VALID_VIDEO: "Please enter valid video url",
  PERCENTAGE: "PERCENTAGE",
  FLAT: "FLAT",
  ROLE: "Please select role",
  NUGGETS_TYPE: "Please select nugget type",
  MEAL: "Please select Meal Type",
  MG_STATUS: "Please select Status",
  TAGGING_LABEL: "Please enter label",
  PENDING: "PENDING",
  PAYMENT_ISSUE: "PAYMENT_ISSUE",
  CANCEL: "CANCELLED",
  APPLEWATCH: "AppleWatch",
  HUMOTRONRING: "HumotronRing",
  FITBIT: "FitBit",
  WEIGHTMACHINE: "WeightMachine",
  WEIGHTMACHINE_ADVANCED:"WeightMachineAdvanced",
  MALE: "MALE",
  FEMALE: "FEMALE",
  OTHER: "OTHER",
  DEFAULT:"DEFAULT",
  PROMOCODE: "Please enter promocode",
  DISCOUNT: "Please select discount type",
  DISCOUNT_VALUE: "Please enter discount value",
  START_DATE: "Please select start date",
  END_DATE: "Please select end date",
  END_DATE_VALIDATION: "End date must be greater than or equal to start date",
  LOGGED_OUT: "You are successfully Logout!",
  MASTER_ALL_COURSE_TEXT: "Master all course price text is required",
  COURSE_TITLE: "Please select course title",
  THUMB_IMAGE:
    "https://drive.google.com/thumbnail?id=1F64VND3vJuFtCPxekGycvTrfr-xcRab1&sz=w1000",
    DUMMY_USER_IMAGE:"https://drive.google.com/thumbnail?id=1553U-j9VLUEZr9pNv2sIqEPccnCMAK-D&sz=w1000",

  ROLES: {
    ADMIN: "ADMIN",
    USER: "USER",
    GUEST_USER: "GUEST-USER",
    WAREHOUSE: "WAREHOUSE",
    CONTENT: "CONTENT",
    CRM: "CRM",
  },
  NUGGET_TAG: {
    CATEGORY: "CATEGORY",
    PRIMARY_TAG: "PRIMARY",
    SECONDARY_TAG: "SECONDARY",
  },
  TAG_LEVEL: {
    CONTENT_TAG: "CONTENT TAG",
    CONTEXT_TAG: "CONTEXT TAG",
  },
  COOKING_COMPLEXITY: {
    HARD: "HARD",
    EASY: "EASY",
    MEDIUM: "MEDIUM",
  },
  RECOMMENDATION_TAG: {
    INCREASE: "INCREASE",
    REDUCE: "REDUCE",
    MAINTAIN: "MAINTAIN",
  },
  MIN_READING: {
    DAYS: "DAYS",
    HOURS: "HOURS",
    MEASUREMENT: "MEASUREMENT",
    SLEEP_NIGHTS: "SLEEP NIGHTS",
  },
  MEALS: {
    BREAKFAST: "BREAKFAST",
    LUNCH: "LUNCH",
    DINNER: "DINNER",
  },
  RECIPES: {
    DIETARY: "DIETARY",
    APPETIZERS: "APPETIZERS",
    DESSERTS: "DESSERTS",
    SNACKS: "SNACKS",
    SOUP: "SOUP",
    MAINCOURSE: "MAIN COURSE",
  },
  TASTES: {
    SPICY: "SPICY",
    SWEET: "SWEET",
    SOUR: "SOUR",
    BITTER: "BITTER",
    SALTY: "SALTY",
    SAVORY: "SAVORY",
  },
  TASTES_ARRAY: ["SPICY", "SWEET", "SOUR", "BITTER", "SALTY", "SAVORY"],
  COOKINGDEVICE: {
    COOKER: "COOKER",
    STOVE: "STOVE",
    TOASTER: "TOASTER",
    MIXER: "MIXER",
    STEAMER: "STEAMER",
  },
  STATUS: {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  },

  INTEREST_QUESTION_TYPE: {
    SOURCE: "SOURCE",
    METRIC: "METRIC",
    GOAL: "GOAL",
  },

  MODE: {
    NORMAL: "NORMAL",
  },

  MODULES: {
    PRODUCT: "PRODUCT",
    USER: "USER",
    ORDERS: "ORDERS",
    PROMO_CODE: "PROMO CODE",
    CATEGORY: "DEVICE CATEGORY",
    MODEL: "DEVICE MODEL",
    SUB_CATEGORY: "DEVICE SUB CATEGORY",
    SKU: "SKU",
    INGREDIENT: "INGREDIENT",
    MARKETING: "MARKETING",
    DOSAGE_ASSISTANCE: "DOSAGE ASSISTANCE",
    BENEFIT: "BENEFIT",
    FAQ: "FAQ",
    KEYWORD: "KEYWORD",
    HOW_IT_WORK: "HOW IT WORK",
    DELIVERY_OPTIONS: "DELIVERY OPTIONS",
    TRAINER: "TRAINER",
    CLASS: "CLASS",
    CHAPTER: "CHAPTER",
    COURSE: "COURSE",
    COURSEFAQ: "COURSE FAQ",
    MASTER: "MASTER",
    CHATGPT: "CHATGPT",
    BOT: "BOT",
    USER_PROFILE_CATEGORY: "USER PROFILE CATEGORY",
    SUB_METRIC: "SUB METRIC",
    USER_PROFILE_SUB_CATEGORY: "USER PROFILE SUB CATEGORY",
    USER_PROFILE_QUESTION: "USER PROFILE QUESTION",
    METRICS: "METRICS",
    CRON_JOB: "CRON JOB",
    MOBILE_SCREEN: "MOBILE SCREEN",
    USER_DETAIL_SCREEN: "USER DETAIL SCREEN",
    INTERESTS: "INTERESTS",
    INTERESTS_QUESTIONS: "INTERESTS QUESTIONS",
    LEVEL_ONE_MAPPING: "LEVEL ONE MAPPING",
    LEVEL_ZERO_MAPPING: "LEVEL ZERO MAPPING",
    GOAL: "GOAL",
    LAB_TEST: "LAB TEST",
    DEVICE: "DEVICE",
    ASSESSMENT: "ASSESSMENT",
    ASSESSMENT_QUESTION:"ASSESSMENT QUESTION",
    RECOMMENDATION_RULE:"RECOMMENDATION RULE",
    RECIPE:"RECIPE",
    RECIPE_BUNDLE:"RECIPE BUNDLE",
    NUGGETS:"NUGGETS",
    NUGGETS_TAG:"NUGGETS TAG",
    PRIVACY_POLICY:"PRIVACY POLICY",
    TERMS_OF_USE_POLICY:"TERMS OF USE POLICY",
    CAREER:"CAREER",
    BOOK:"BOOK",
    INVENTORY:"INVENTORY",
    VARIANT:"VARIANT",
  },
});
