import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    isValidHttpUrl,
    isValidUrl,
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import { DeviceService } from "../../../services/device.service";
import { Switch } from "@material-ui/core";
import Select from "react-select";
import { interestQuestionService, DeviceSubCategoryService, DeviceModelService } from "../../../services";

const AddEditDevice = () => {
    const { setDevicesData, devicesData, deviceSubCategoryData, setDeviceSubCategoryData, deviceModelData, setDeviceModelData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [deviceId, setDeviceId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [fieldsDisabled, setFieldsDisabled] = useState(false);
    const [selectedSubCategoryName, setSelectedSubCategoryName] = useState("");
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");

    const [selectedModelName, setSelectedModelName] = useState("");
    const [selectedModelId, setSelectedModelId] = useState("");
    const [deviceAllData, setDeviceAllData] = useState({
        deviceName: "",
        deviceFacingName: "",
        deviceImage: "",
        deviceIcon: "",
        deviceType: "",
        isReading: CONSTANT.TRUE,
        minReading: { unit: "", value: "" },
        status: "",
        deviceSubCategoryId: "",
        deviceModelId: ""
    });

    const [toggleState, setToggleState] = useState(deviceAllData.isReading === CONSTANT.TRUE);

    const handleToggleChange = (event: any) => {
        const isChecked = event.target.checked;
        setDeviceAllData((prevState: any) => ({
            ...prevState,
            isReading: isChecked ? CONSTANT.TRUE : CONSTANT.FALSE
        }));
        setToggleState(isChecked);
    };

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setDeviceId(decryptData(params.id));
                getDeviceById(decryptData(params.id));
                setFieldsDisabled(true);
            }
        };
        init();
        if(deviceModelData?.length === 0 ){
            getAllDeviceSubCategoryName();
        }
        if(deviceSubCategoryData?.length === 0 ){
            getAllDeviceModelName();
        }
    }, []);

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        deviceName: yup.string().required(" required"),
        deviceImage: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        deviceIcon: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        deviceType: yup.string().required("required"),
    });

    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...deviceAllData },
            validationSchema,

            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const followUpToggleValue = toggleState ? CONSTANT.TRUE : CONSTANT.FALSE;
                    const payload = {
                        ...values,
                        isReading: followUpToggleValue,
                        deviceSubCategoryId: selectedSubCategoryId,
                        deviceModelId: selectedModelId,
                    }
                    const data = deviceId ? await DeviceService.editDevice(deviceId, payload) : await DeviceService.addDevice(payload);
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const devicename = await interestQuestionService.getAllDevice();
                        setDevicesData(devicename.data.device);
                        action.resetForm();
                        navigate(ROUTING_CONSTANT.DEVICE_VIEW);

                        const { _id, deviceName } = data.data;
                        if (deviceId) {
                            const existingIndex = devicesData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setDevicesData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, deviceName };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    const getDeviceById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await DeviceService.getDeviceById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setDeviceAllData(response?.data?.device);
                setValues({ ...response?.data?.device });
                setToggleState(response?.data?.device.isReading === CONSTANT.TRUE);
                setSelectedSubCategoryName(response?.data?.device?.deviceSubCategoryId?.deviceSubCategoryName);
                setSelectedSubCategoryId(response?.data?.device?.deviceSubCategoryId?._id)
                setSelectedModelName(response?.data?.device?.deviceModelId?.deviceModelName);
                setSelectedModelId(response?.data?.device?.deviceModelId?._id)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const getAllDeviceSubCategoryName = async () => {
        try {
          let data: any = await DeviceSubCategoryService.getAllDeviceSubCategoryName();
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.deviceSubCategory;
            setDeviceSubCategoryData(responseData);
          } else {
            toast.error(data.message);
          }
        } catch (error) {
          toast.error(CONSTANT.ERROR_OCCURRED);
        }
    };

    const getAllDeviceModelName = async () => {
        try {
          let data: any = await DeviceModelService.getAllDeviceModelName();
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.deviceModel;
            setDeviceModelData(responseData);
          } else {
            toast.error(data.message);
          }
        } catch (error) {
          toast.error(CONSTANT.ERROR_OCCURRED);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.DEVICE_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{deviceId ? CONSTANT.UPDATE : CONSTANT.ADD} Device</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Device Name*</label>
                                <FormGroup id="deviceName">
                                    <Input
                                        className={`${isInvalidForm && !values.deviceName
                                            ? "border border-danger input-form "
                                            : "input-form"
                                            } `}
                                        name="deviceName"
                                        placeholder="Device Name"
                                        value={values.deviceName}
                                        onChange={handleChange}
                                        // disabled={fieldsDisabled}
                                    />
                                </FormGroup>

                                {isInvalidForm && !values.deviceName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Device Facing Name*</label>
                                <FormGroup id="deviceFacingName">
                                    <Input
                                        className={`${isInvalidForm && !values.deviceFacingName
                                            ? "border border-danger input-form "
                                            : "input-form"
                                            } `}
                                        name="deviceFacingName"
                                        placeholder="Device Facing Name"
                                        value={values.deviceFacingName}
                                        onChange={handleChange}
                                        // disabled={fieldsDisabled}
                                    />
                                </FormGroup>

                                {isInvalidForm && !values.deviceFacingName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Device Image URL*</label>

                                <FormGroup id="deviceImage">
                                    <Input
                                        placeholder="Device Image URL"
                                        className={`${isInvalidForm && !values.deviceImage
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="deviceImage"
                                        value={values.deviceImage}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm && isValidHttpUrl(values.deviceImage) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Device Type*</label>

                                <FormGroup id="deviceType">
                                    <Input
                                        placeholder="Device Type"
                                        className={`${isInvalidForm && !values.deviceType
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="deviceType"
                                        value={values.deviceType}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.deviceType && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.TYPE}</small>
                                            </p>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Device Icon</label>

                                <FormGroup id="deviceIcon">
                                    <Input
                                        placeholder="Device Icon"
                                        className={`${isInvalidForm && !values.deviceIcon
                                            ? "border input-form border-danger"
                                            : "input-form"
                                            }`}
                                        name="deviceIcon"
                                        value={values.deviceIcon}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm &&
                                    isValidHttpUrl(values.deviceIcon) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.ICON}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Device Status</label>
                                <FormGroup id="deviceType">
                                    <select
                                        className={`${isInvalidForm && values.status.length === 0
                                            ? "border form-select form-selet-lg input-form"
                                            : "form-select form-selet-lg input-form"
                                            }`}
                                        name="status"
                                        value={values.status}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Device Status</option>
                                        <option value={CONSTANT.STATUS.ACTIVE}>
                                            {CONSTANT.STATUS.ACTIVE}
                                        </option>
                                        <option value={CONSTANT.STATUS.INACTIVE}>
                                            {CONSTANT.STATUS.INACTIVE}
                                        </option>
                                    </select>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Take-Reading</label>
                                <FormGroup id="deviceType">
                                    <Switch
                                        checked={toggleState}
                                        onChange={handleToggleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color='primary'
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-12">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <label className="form-labels">Min Reading</label>
                                        </div>
                                    </>
                                    <div>
                                        <div className="">
                                            <div className="d-flex gap-2 align-items-center justify-content-center mt-3">
                                                <select
                                                    className={`${isInvalidForm && values?.minReading?.unit?.length === 0
                                                        ? "border form-select form-selet-lg input-form"
                                                        : "form-select form-selet-lg input-form"
                                                        }`}
                                                    name={`minReading.unit`}
                                                    value={values?.minReading?.unit}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Min Reading Unit</option>
                                                    <option value={CONSTANT.MIN_READING.DAYS}>
                                                        {CONSTANT.MIN_READING.DAYS}
                                                    </option>
                                                    <option value={CONSTANT.MIN_READING.HOURS}>
                                                        {CONSTANT.MIN_READING.HOURS}
                                                    </option>
                                                    <option value={CONSTANT.MIN_READING.MEASUREMENT}>
                                                        {CONSTANT.MIN_READING.MEASUREMENT}
                                                    </option>
                                                    <option value={CONSTANT.MIN_READING.SLEEP_NIGHTS}>
                                                        {CONSTANT.MIN_READING.SLEEP_NIGHTS}
                                                    </option>
                                                </select>
                                                <Input
                                                    placeholder="Min Reading Value"
                                                    className={`${isInvalidForm && !values?.minReading?.value ? "border input-form" : "input-form"}`}
                                                    value={values?.minReading?.value}
                                                    type="number"
                                                    name={`minReading.value`}
                                                    onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Select Device Sub Category*</label>

                                <FormGroup id="categoryName">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            setSelectedSubCategoryId(
                                                selectedOption ? selectedOption.value : ""
                                            );
                                            setSelectedSubCategoryName(
                                                selectedOption ? selectedOption.label : ""
                                            );
                                        }}
                                        options={
                                            deviceSubCategoryData &&
                                            deviceSubCategoryData.map((deviceSubCategory: any) => ({
                                                value: deviceSubCategory._id,
                                                label: deviceSubCategory.deviceSubCategoryName,
                                            }))
                                        }
                                        placeholder="Select Device Sub Category"
                                        value={
                                            selectedSubCategoryName
                                                ? {
                                                    value: selectedSubCategoryId,
                                                    label: selectedSubCategoryName,
                                                }
                                                : null
                                        }
                                        className={`${isInvalidForm && !selectedSubCategoryName
                                            ? "border border-danger"
                                            : "border rounded-circle"
                                            }`}
                                        isSearchable={true}
                                        isClearable
                                    />
                                </FormGroup>
                                {isInvalidForm && selectedSubCategoryName?.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.CATEGORY_ID}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Select Device Model*</label>

                                <FormGroup id="categoryName">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            setSelectedModelId(
                                                selectedOption ? selectedOption.value : ""
                                            );
                                            setSelectedModelName(
                                                selectedOption ? selectedOption.label : ""
                                            );
                                        }}
                                        options={
                                            deviceModelData &&
                                            deviceModelData.map((deviceModel: any) => ({
                                                value: deviceModel._id,
                                                label: deviceModel.deviceModelName,
                                            }))
                                        }
                                        placeholder="Select Device Model"
                                        value={
                                            selectedModelName
                                                ? {
                                                    value: selectedModelId,
                                                    label: selectedModelName,
                                                }
                                                : null
                                        }
                                        className={`${isInvalidForm && !selectedModelName
                                            ? "border border-danger"
                                            : "border rounded-circle"
                                            }`}
                                        isSearchable={true}
                                        isClearable
                                    />
                                </FormGroup>
                                {isInvalidForm && selectedModelName.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.MODEL_ID}</small>
                                    </p>
                                )}
                            </div>

                            <div>
                                <Button
                                    type="submit"
                                    onClick={handleSave}
                                    isDisable={isLoading}
                                    className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                        }`}
                                >
                                    {deviceId ? CONSTANT.UPDATE : CONSTANT.ADD} Device
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="w-25 mt-5 cancel-btn float-end"
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditDevice;
