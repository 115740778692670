import httpCommon from '../core/http-common';
import { logoutUser } from '../core/auth.service';
import { API_URL } from '../core/constant';
import { toast } from 'react-toastify';
import { CONSTANT } from '../core/static-constant';
import HttpMultipartCommonCore from '../core/HttpMultipartCommonCore';

export const RecipeBundleService = {
    getAllRecipeBundle,
    deleteRecipeBundleById,
    getRecipeBundleById,
    updateRecipeBundleById,
    createRecipeBundle,
    getAllRecipeBundleName,
    uploadRecipeBundleSheet
};

function getAllRecipeBundle(data: any) {
    return httpCommon.post(API_URL.GET_ALL_RECIPE_BUNDLE, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function deleteRecipeBundleById(id: any) {
    return httpCommon.post(API_URL.DELETE_RECIPE_BUNDLE_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getRecipeBundleById(id: any) {
    return httpCommon.get(API_URL.GET_RECIPE_BUNDLE_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function updateRecipeBundleById(id: any, data: any) {
    return httpCommon.post(API_URL.UPDATE_RECIPE_BUNDLE_BY_ID + id, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function createRecipeBundle(data: any) {
    return httpCommon.post(API_URL.CREATE_RECIPE_BUNDLE, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getAllRecipeBundleName() {
    return httpCommon.get(API_URL.GET_ALL_RECIPE_BUNDLE_NAME).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function uploadRecipeBundleSheet(data: any) {
    let url = API_URL.UPLOAD_RECIPE_BUNDLE_SHEET;
    return HttpMultipartCommonCore.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}
