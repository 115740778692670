import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { NuggetsService } from "../../../services/nuggets.service";
import { NuggetsTagService } from "../../../services/nuggetsTag.service";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import Select from "react-select";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { BookService } from "../../../services";

const AddEditNuggets = () => {
    const { setNuggetData, nuggetData, nuggetTagData, setNuggetTagData, bookData, setBookData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [NuggetsId, setNuggetsId] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [selectedDeviceName, setSelectedDeviceName] = useState("");
    const [selectedDeviceId, setSelectedDeviceId] = useState<any>("");
    const [categoryId, setCategoryId] = useState("");

    const [selectedBookName, setSelectedBookName] = useState<string[]>([]);
    const [selectedBookId, setSelectedBookId] = useState<string[]>([]);

    const [nuggetTagAllData, setNuggetTagAllData] = useState<any>("");
    const [NuggetsAllData, setNuggetsAllData] = useState<any>({
        uniqueId: "",
        learningLevel: "",
        nuggetTopic: "",
        overview: "",
        callAction: "",
        expertCommentary: "",
        communityExperience: "",
        anecdotes: [],
        deepDives: [{ title: "", subtitle: "", content: "", source: "" }],
        bookRecommendation: [{ book: "", author: "", purchaseLink: "" }],
    });

    useEffect(() => {
        // if (nuggetTagData?.length === 0) {
        getAllNuggetTagName();
        // }
        getAllNuggetTagByType();
        getAllBookName();
    }, []);

    useEffect(() => {
        const categoryIDs = nuggetTagData.filter((ele: any) => ele.tagName === selectedDeviceName).map((ele: any) => ele.categoryId);
        const categoryIDString = categoryIDs.length > 0 ? categoryIDs[0] : '';
        setCategoryId(categoryIDString);
    }, [selectedDeviceName]);

    const getAllNuggetTagName = async () => {
        try {
            const requestBody = {
                tagLevel: CONSTANT.NUGGET_TAG.PRIMARY_TAG,
                tagType: CONSTANT.TAG_LEVEL.CONTEXT_TAG
            };
            let data = await NuggetsTagService.getAllNuggetTagByType(requestBody);
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.nuggetTag;
                setNuggetTagData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const getAllBookName = async () => {
        try {
            let data = await BookService.getAllBookName();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.book;
                setBookData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const validationSchema = yup.object().shape({
        learningLevel: yup.string().required("required"),
        nuggetTopic: yup.string().required("required"),
    });

    const getAllNuggetTagByType = async () => {
        try {
            const requestBody = {
                tagLevel: CONSTANT.NUGGET_TAG.CATEGORY,
                tagType: CONSTANT.TAG_LEVEL.CONTENT_TAG
            };
            let data = await NuggetsTagService.getAllNuggetTagByType(requestBody);
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.nuggetTag;
                setNuggetTagAllData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const handleAddOption = () => {
        const newOption = { title: "", subtitle: "", content: "", source: "" };
        if (values.deepDives.every((option: any) => option.title !== "" && option.subtitle !== "" && option.content !== "" && option.source !== "")) {
            setValues((prevValues: any) => ({
                ...prevValues,
                deepDives: [...prevValues.deepDives, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveOption = (index: any) => {
        setValues((prevValues: any) => {
            const updatedOptions = [...prevValues.deepDives];
            updatedOptions.splice(index, 1);
            return { ...prevValues, deepDives: updatedOptions };
        });
    };

    const handleAddBookOption = () => {
        const newOption = { book: "", author: "", purchaseLink: "" };
        if (values.bookRecommendation.every((option: any) => option.book !== "" && option.author !== "" && option.purchaseLink !== "")) {
            setValues((prevValues: any) => ({
                ...prevValues,
                bookRecommendation: [...prevValues.bookRecommendation, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveBookOption = (index: any) => {
        setValues((prevValues: any) => {
            const updatedOptions = [...prevValues.bookRecommendation];
            updatedOptions.splice(index, 1);
            return { ...prevValues, bookRecommendation: updatedOptions };
        });
    };

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...NuggetsAllData },
            validationSchema,
            onSubmit: async (values, action) => {
                try {
                    if (!values.deepDives || values.deepDives.length === 0) {
                        toast.error("Options are required.");
                        setIsLoading(false);
                        return;
                    }
                    for (const option of values.deepDives) {
                        if (!option.title || !option.subtitle || !option.content || !option.source) {
                            toast.error("Options are required.");
                            setIsLoading(false);
                            return;
                        }
                    }
                    // if (!values.bookRecommendation || values.bookRecommendation.length === 0) {
                    //     toast.error("Options are required.");
                    //     setIsLoading(false);
                    //     return;
                    // }
                    // for (const option of values.bookRecommendation) {
                    //     if (!option.title || !option.author || !option.purchaseLink) {
                    //         toast.error("Options are required.");
                    //         setIsLoading(false);
                    //         return;
                    //     }
                    // }
                    const filteredValues: any = {
                        ...values,
                        primaryTag: selectedDeviceId,
                        category: categoryId,
                        anecdotes: contentTypes,
                        bookRecommendation: values.bookRecommendation.map((item: any) => ({
                            purchaseLink: item.purchaseLink,
                            author: item.author,
                            bookTitle: item.bookTitle,
                            bookRecommendationId: item.bookRecommendationId
                        })),
                    };
                    setIsLoading(true);
                    const data = NuggetsId ? await NuggetsService.updateNuggetById(NuggetsId, filteredValues) : await NuggetsService.addNugget(filteredValues);
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const MetricName = await NuggetsService.getAllNuggetName();
                        setNuggetData(MetricName?.data?.nugget);
                        navigate(ROUTING_CONSTANT.NUGGETS_VIEW);
                        action.resetForm();

                        const { _id, name } = data.data;

                        if (NuggetsId) {
                            const existingIndex = nuggetData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setNuggetData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, name };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setNuggetsId(decryptData(params.id));
                getNuggetById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const getNuggetById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await NuggetsService.getNuggetById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setNuggetsAllData(response?.data?.nugget);
                setValues({ ...response?.data?.nugget });
                // const selectedNuggetsNamesss = response?.data?.nugget?.secContextTags.map(
                //     (nuggets: any) => ({
                //         id: nuggets._id,
                //         label: nuggets.tagName,
                //     })
                // );
                // setSelectedNuggetTagName(selectedNuggetsNamesss);

                // const nugget = response?.data?.nugget?.secContextTags.map((nuggets: any) => nuggets._id);
                // setSelectedNuggetTagIdArr(nugget);

                setSelectedTags(response);
                setSelectedDeviceName(response?.data?.nugget?.primaryTag?.tagName);
                setSelectedDeviceId(response?.data?.nugget?.primaryTag?._id);
                setCategoryId(response?.data?.nugget?.category?._id)

                setSelectedBookName(response?.data?.nugget?.bookRecommendation?.map((ele: any) => ele.bookTitle) || []);
                setSelectedBookId(response?.data?.nugget?.bookRecommendation?.map((ele: any) => ele.bookRecommendation) || []);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const setSelectedTags = (response: any) => {
        if (response?.data?.nugget?.anecdotes) {
            const anecdotes = response.data.nugget.anecdotes;
            const updatedContentTypes = anecdotes.map((contentType: any) => ({
                tag: contentType.tag._id,
                // secondaryTags: contentType.secondaryTags.map((tag: any) => tag._id),
                content: contentType.content || '',
                source: contentType.source || '',
            }));
            setContentTypes(updatedContentTypes);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.NUGGETS_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }
    const initialContentType: any = {
        tag: '',
        content: '',
        source: ''
    };

    const [contentTypes, setContentTypes] = useState<any>([initialContentType]);

    const handleAddContentType = () => {
        const newContentType = { ...initialContentType };

        setContentTypes((prevContentTypes: any) => {
            const updatedContentTypes = [...prevContentTypes, newContentType];

            setNuggetsAllData((prevData: any) => ({
                ...prevData,
                anecdotes: updatedContentTypes // Use updated contentTypes
            }));

            return updatedContentTypes;
        });
    };

    const handleRemoveContentType = (index: number) => {
        setContentTypes((prevContentTypes: any) => {
            const updatedContentTypes = [...prevContentTypes];
            updatedContentTypes.splice(index, 1);
            return updatedContentTypes;
        });
        setNuggetsAllData((prevData: any) => ({
            ...prevData,
            anecdotes: prevData?.anecdotes?.filter((_: any, i: any) => i !== index)
        }));
    };

    const handleInputChange = (index: number, e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setContentTypes((prevContentTypes: any) => {
            const updatedContentTypes = [...prevContentTypes];
            updatedContentTypes[index] = { ...updatedContentTypes[index], [name]: value };

            // Update NuggetsAllData
            setNuggetsAllData((prevData: any) => ({
                ...prevData,
                anecdotes: updatedContentTypes
            }));

            return updatedContentTypes;
        });
    };

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {NuggetsId ? CONSTANT.UPDATE : CONSTANT.ADD} Nugget
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Unique ID*</label>

                                <FormGroup id="uniqueId">
                                    <Input
                                        placeholder="Unique ID"
                                        className={`${isInvalidForm && !values.uniqueId
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="uniqueId"
                                        value={values.uniqueId}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.uniqueId && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Learning Level*</label>

                                <FormGroup id="learningLevel">
                                    <Input
                                        placeholder="Learning Level"
                                        className={`${isInvalidForm && !values.learningLevel
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="learningLevel"
                                        value={values.learningLevel}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.learningLevel && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Nuggets Topic Name*</label>

                                <FormGroup id="nuggetTopic">
                                    <Input
                                        placeholder="Nuggets Topic Name"
                                        className={`${isInvalidForm && !values.nuggetTopic
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="nuggetTopic"
                                        value={values.nuggetTopic}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.nuggetTopic && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6" key="nuggets">
                                <label className="form-labels">Select Primary Tag*</label>
                                <FormGroup id="device">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            setSelectedDeviceId(
                                                selectedOption ? selectedOption.value : ""
                                            );
                                            setSelectedDeviceName(
                                                selectedOption ? selectedOption.label : ""
                                            );
                                        }}
                                        options={
                                            nuggetTagData &&
                                            nuggetTagData
                                                .map((device: any) => ({
                                                    value: device._id,
                                                    label: device.tagName,
                                                }))
                                        }
                                        placeholder="Select Primary Tag"
                                        value={
                                            selectedDeviceName
                                                ? {
                                                    value: selectedDeviceId,
                                                    label: selectedDeviceName,
                                                }
                                                : null
                                        }
                                        isSearchable={true}
                                        className={`${isInvalidForm && !selectedDeviceName
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                    />
                                </FormGroup>
                                {isInvalidForm && selectedDeviceId.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-12">
                                <FormGroup id="options">
                                    <div>
                                        <>
                                            <label className="form-labels">Anecdotes</label>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddContentType}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg>
                                        </>
                                    </div>
                                    <div>
                                        {contentTypes?.map((contentType: any, index: any) => (
                                            <div key={index} style={{ marginBottom: '20px' }} className="d-flex gap-2 align-items-center justify-content-center mt-2">
                                                <FormGroup id="device" className="col-md-6">
                                                    <Select
                                                        styles={customStyles}
                                                        onChange={(selectedOption) => {
                                                            setContentTypes((prevState: any) => prevState.map((contentType: any, idx: any) =>
                                                                idx === index
                                                                    ? { ...contentType, tag: selectedOption ? selectedOption.value : "" }
                                                                    : contentType,
                                                            ));
                                                            // if(selectedOption){
                                                            //     getAllSecondaryNuggetTagByPrimaryNuggetTag(selectedOption?.value)
                                                            // }
                                                        }}
                                                        options={
                                                            nuggetTagAllData &&
                                                            nuggetTagAllData.map((devices: any) => ({
                                                                value: devices._id,
                                                                label: devices.tagName,
                                                            }))
                                                        }
                                                        placeholder="Select Anecdotes"
                                                        value={
                                                            contentTypes[index].tag
                                                                ? {
                                                                    value: contentTypes[index].tag,
                                                                    label: nuggetTagAllData.find((tag: any) => tag._id === contentTypes[index].tag)?.tagName || ""
                                                                }
                                                                : null
                                                        }
                                                        className={`${"border border rounded-circle"
                                                            }`}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </FormGroup>
                                                <Textarea
                                                    rows={5}
                                                    placeholder="Content"
                                                    className={`${isInvalidForm && !values.learningLevel
                                                        ? "border input-form"
                                                        : "input-form"
                                                        }`}
                                                    name="content"
                                                    value={contentType.content}
                                                    onChange={(e: any) => handleInputChange(index, e)}
                                                />
                                                <Textarea
                                                    rows={5}
                                                    placeholder="Source"
                                                    className={`${isInvalidForm && !values.learningLevel
                                                        ? "border input-form"
                                                        : "input-form"
                                                        }`}
                                                    name="source"
                                                    value={contentType.source}
                                                    onChange={(e: any) => handleInputChange(index, e)}
                                                />
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-dash-circle sku-icon cursor-pointer mt-1"
                                                    viewBox="0 0 16 16"
                                                    onClick={() => handleRemoveContentType(index)}
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                </svg>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Overview*</label>

                                <FormGroup id="overview">
                                    <Input
                                        placeholder="Overview"
                                        className={`${isInvalidForm && !values.overview
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="overview"
                                        value={values.overview}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.overview && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Call to Action*</label>

                                <FormGroup id="callAction">
                                    <Input
                                        placeholder="Call to Action"
                                        className={`${isInvalidForm && !values.callAction
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="callAction"
                                        value={values.callAction}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.callAction && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Expert Commentary & Community Insights*</label>

                                <FormGroup id="expertCommentary">
                                    <Input
                                        placeholder="Expert Commentary & Community Insights"
                                        className={`${isInvalidForm && !values.expertCommentary
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="expertCommentary"
                                        value={values.expertCommentary}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.expertCommentary && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Community Experience*</label>

                                <FormGroup id="communityExperience">
                                    <Input
                                        placeholder="Community Experience"
                                        className={`${isInvalidForm && !values.communityExperience
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="communityExperience"
                                        value={values.communityExperience}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.communityExperience && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-12">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <><label className="form-labels">Deep Dives*</label><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddOption}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg></>
                                        </div>
                                    </>
                                    <div>
                                        {values?.deepDives?.map((option: any, index: any) => (
                                            <div key={index} className="">
                                                <div className="d-flex gap-2 align-items-center justify-content-center mt-1">
                                                    <Input
                                                        placeholder="Deep Dives Title"
                                                        className={`${isInvalidForm && !option.title ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.title}
                                                        name={`deepDives[${index}].title`}
                                                        onChange={handleChange} />
                                                    <Input
                                                        placeholder="Deep Dives Subtitle"
                                                        className={`${isInvalidForm && !option.subtitle ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.subtitle}
                                                        name={`deepDives[${index}].subtitle`}
                                                        onChange={handleChange} />
                                                    <Input
                                                        placeholder="Deep Dives Content"
                                                        className={`${isInvalidForm && !option.content ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.content}
                                                        name={`deepDives[${index}].content`}
                                                        onChange={handleChange} />
                                                    <Input
                                                        placeholder="Deep Dives Source"
                                                        className={`${isInvalidForm && !option.source ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.source}
                                                        name={`deepDives[${index}].source`}
                                                        onChange={handleChange} />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveOption(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                                {isInvalidForm && values.deepDives.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.OPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-12">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <><label className="form-labels">Book Recommendation*</label><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddBookOption}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg></>
                                        </div>
                                    </>
                                    <div>
                                        {values?.bookRecommendation?.map((option: any, index: any) => (
                                            <div key={index} className="">
                                                <div className="d-flex gap-2 align-items-center justify-content-center mt-1">
                                                    <div className="col-sm-6 col-md-3" key="book">
                                                        <FormGroup id="book">
                                                            <Select
                                                                styles={customStyles}
                                                                onChange={(selectedOption) => {
                                                                    const updatedRecipeBundleId = [...values.bookRecommendation];
                                                                    updatedRecipeBundleId[index] = {
                                                                        ...updatedRecipeBundleId[index],
                                                                        book: selectedOption ? selectedOption.value : "",
                                                                        bookTitle: selectedOption ? selectedOption.label : "",
                                                                        bookRecommendation: selectedOption ? selectedOption.value : "",
                                                                    };
                                                                    setValues({
                                                                        ...values,
                                                                        bookRecommendation: updatedRecipeBundleId,
                                                                    });

                                                                    const newSelectedRecipeBundleNames = [...selectedBookName];
                                                                    const newSelectedRecipeBundleIds = [...selectedBookId];
                                                                    newSelectedRecipeBundleNames[index] = selectedOption ? selectedOption.label : "";
                                                                    newSelectedRecipeBundleIds[index] = selectedOption ? selectedOption.value : "";
                                                                    setSelectedBookName(newSelectedRecipeBundleNames);
                                                                    setSelectedBookId(newSelectedRecipeBundleIds);
                                                                }}
                                                                options={
                                                                    bookData &&
                                                                    bookData.map((book: any) => ({
                                                                        value: book._id,
                                                                        label: book.bookTitle,
                                                                    }))
                                                                }
                                                                placeholder="Select Book"
                                                                value={
                                                                    selectedBookName[index] && selectedBookId[index]
                                                                        ? {
                                                                            value: selectedBookId[index],
                                                                            label: selectedBookName[index],
                                                                        }
                                                                        : null
                                                                }
                                                                isSearchable={true}
                                                                className={`${isInvalidForm && selectedBookName.length === 0
                                                                    ? "border border-danger"
                                                                    : ""
                                                                    }`}
                                                            />
                                                        </FormGroup>
                                                        {isInvalidForm && selectedBookName.length === 0 && (
                                                            <p className="text-danger ms-2">
                                                                <small>{CONSTANT.FIELD_NAME}</small>
                                                            </p>
                                                        )}
                                                    </div>
                                                    <Input
                                                        placeholder="Book Recommendation Author"
                                                        className={`${isInvalidForm && !option.author ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.author}
                                                        name={`bookRecommendation[${index}].author`}
                                                        onChange={handleChange} />
                                                    <Input
                                                        placeholder="Book Recommendation Purchase Link"
                                                        className={`${isInvalidForm && !option.purchaseLink ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.purchaseLink}
                                                        name={`bookRecommendation[${index}].purchaseLink`}
                                                        onChange={handleChange} />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveBookOption(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                                {isInvalidForm && values.deepDives.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.OPTION}</small>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 mt-5 process-btn ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {NuggetsId ? CONSTANT.UPDATE : CONSTANT.ADD} Nugget
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div >
    );
};

export default AddEditNuggets;
