import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { ROUTING_CONSTANT } from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import { DeliveryOptionsService } from "../../../services/deliveryOptions.service";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import Select from "react-select";

const AddEditDeliveryOptions = () => {
    const { deliveryOption, setDeliveryOption }: any =
        useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [deliveryOptionsId, setDeliveryOptionsId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [deliveryOptionsAllData, setDeliveryOptionsData] = useState({
        methodName: "",
        price: "",
        maxDays: "",
        minDays: "",
        cartValue: "",
        isFree: "",
        termsAndCondition: "",
        methodDescription: "",
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const validationSchema = yup.object().shape({
        methodName: yup.string().required(" required"),
        price: yup.string().required(" required"),
        termsAndCondition: yup.string().required("required"),
        methodDescription: yup.string().required("required"),
    });

    const { values, errors, touched, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...deliveryOptionsAllData },
            validationSchema,

            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const data = deliveryOptionsId ? await DeliveryOptionsService.updateDeliveryOptionsById(deliveryOptionsId, { ...values, }) : await DeliveryOptionsService.createDeliveryOption({ ...values, });
                    setIsLoading(false);
                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        action.resetForm();
                        navigate(ROUTING_CONSTANT.DELIVERY_OPTIONS_VIEW);
                        const { _id, benefitName } = data.data;

                        if (deliveryOptionsId) {
                            const existingIndex = deliveryOption.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setDeliveryOption((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, benefitName };
                                    return newData;
                                });
                            }
                        }
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            }
        });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setDeliveryOptionsId(decryptData(params.id));
                getDeliveryOptionsById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const getDeliveryOptionsById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await DeliveryOptionsService.getDeliveryOptionsById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setDeliveryOptionsData(response?.data?.deliveryOption);
                setValues({ ...response?.data?.deliveryOption });
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.DELIVERY_OPTIONS_VIEW);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {deliveryOptionsId ? CONSTANT.UPDATE : CONSTANT.ADD} Delivery
                            Option
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Delivery Method Name*</label>
                                <FormGroup id="methodName" >
                                    <Input
                                        placeholder="Delivery Method Name"
                                        className={`${isInvalidForm && !values.methodName
                                            ? "border border-danger"
                                            : ""
                                            }input-form`}
                                        name="methodName"
                                        value={values.methodName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.methodName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Delivery Method Price*</label>
                                <FormGroup id="price" >
                                    <Input
                                        placeholder="Delivery Method Price"
                                        type="number"
                                        className={`${isInvalidForm && !values.price
                                            ? "border border-danger"
                                            : ""
                                            }input-form`}
                                        name="price"
                                        value={values.price}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.price && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.PRICE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                            <label className="form-labels">Delivery Method Min Days*</label>
                                <FormGroup id="minDays" >
                                    <Input
                                        placeholder="Delivery Method Min Days"
                                        type="number"
                                        className={`${isInvalidForm && !values.minDays
                                            ? "border border-danger"
                                            : ""
                                            }input-form`}
                                        name="minDays"
                                        value={values.minDays}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.minDays && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                            <label className="form-labels">Delivery Method Max Days*</label>
                                <FormGroup id="maxDays" >
                                    <Input
                                        placeholder="Delivery Method Max Days"
                                        type="number"
                                        className={`${isInvalidForm && !values.maxDays
                                            ? "border border-danger"
                                            : ""
                                            }input-form`}
                                        name="maxDays"
                                        value={values.maxDays}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.maxDays && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                            <label className="form-labels">Delivery Method Free Above Cart Value*</label>
                                <FormGroup id="cartValue" >
                                    <Input
                                        placeholder="Delivery Method Free Above Cart Value"
                                        type="number"
                                        className={`${isInvalidForm && !values.cartValue
                                            ? "border border-danger"
                                            : ""
                                            }input-form`}
                                        name="cartValue"
                                        value={values.cartValue}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.cartValue && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                            <label className="form-labels">Delivery Method Free/Paid*</label>
                                <FormGroup id="isFree" >
                                    <Input
                                        placeholder="Delivery Method Free/Paid"
                                        className={`${isInvalidForm && !values.isFree
                                            ? "border border-danger"
                                            : ""
                                            }input-form`}
                                        name="isFree"
                                        value={values.isFree}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.isFree && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                            <label className="form-labels">Delivery Method Terms & Condition*</label>
                                <FormGroup
                                    id="termsAndCondition"
                                >
                                    <Input
                                        placeholder="Delivery Method Terms & Condition"
                                        className={`${isInvalidForm && !values.termsAndCondition
                                            ? "border border-danger"
                                            : ""
                                            } input-form`}
                                        name="termsAndCondition"
                                        value={values.termsAndCondition}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.termsAndCondition && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                            <label className="form-labels">Delivery Method Description*</label>
                                <FormGroup
                                    id="methodDescription"
                                >
                                    <Input
                                        placeholder="Delivery Method Description"
                                        className={`${isInvalidForm && !values.methodDescription
                                            ? "border border-danger"
                                            : ""
                                            }input-form`}
                                        name="methodDescription"
                                        value={values.methodDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.methodDescription && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.LONG_DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={() => setIsInvalidForm(true)}
                                isDisable={isLoading}
                                className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                    }`}
                            >
                                {deliveryOptionsId ? CONSTANT.UPDATE : CONSTANT.ADD} Delivery
                                Option
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditDeliveryOptions;
