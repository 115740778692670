import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import CreatableSelect from 'react-select/creatable';
import { CONSTANT } from "../../../core/static-constant";
import { MetricService } from "../../../services/metric.service";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import { ActionMeta } from 'react-select';
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import { BotService } from "../../../services/bot.service";
import { UserHardwareSpecificService } from "../../../services/hardwareSpecific.service";
import Select from "react-select";

type OptionType = {
    __isNew__: OptionType; label: string; value: string
};

const AddEditMetric = () => {
    const { setMetricData, metricData, devicesData, setDevicesData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [MetricId, setMetricId] = useState("");
    const [fieldsDisabled, setFieldsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDeviceName, setSelectedDeviceName] = useState("");
    const [selectedDeviceId, setSelectedDeviceId] = useState<any>("");
    const [MetricAllData, setMetricAllData] = useState({
        metricName: "",
        metricUserFacingName: "",
        dataRange: "",
        metricUnit: "",
        metricDescription: "",
        fieldName: [],
        deviceId:"",
        frequencyUpdate: "",
        status: "",
        // metricWhatWhy: [{ what: "", why: "" }],
        metricWhat: "",
        metricWhy: ""
    });

    const validationSchema = yup.object().shape({
        metricName: yup.string().required("required"),
        dataRange: yup.string().required("required"),
        fieldName: yup.array().required("required"),
        frequencyUpdate: yup.string().required("required"),
        metricUserFacingName: yup.string().required("required"),
        status: yup.string().required("required"),
    });

    const getAllDevice = async () => {
        try {
            let data: any = await UserHardwareSpecificService.getAllListOfDevice();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.device;
                setDevicesData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const { values, handleChange, handleSubmit, setValues, setFieldValue } =
        useFormik({
            initialValues: { ...MetricAllData },
            validationSchema,
            onSubmit: async (values, action) => {
                try {
                    // if (!values.metricWhatWhy || values.metricWhatWhy.length === 0) {
                    //     toast.error("Options are required.");
                    //     setIsLoading(false);
                    //     return;
                    // }
                    // for (const option of values.metricWhatWhy) {
                    //     if (!option.what || !option.why) {
                    //         toast.error("Options are required.");
                    //         setIsLoading(false);
                    //         return;
                    //     }
                    // }
                    if (values.metricName !== values.metricUserFacingName) {
                        toast.error('Metric Name and Metric User Facing Name should be same');
                        return;
                    }
                    const filteredValues: any = { ...values,deviceId: selectedDeviceId, };
                    filteredValues.fieldName = filteredValues.fieldName.map((option: any) => ({
                        label: option.label,
                        value: option.value,
                    }));
                    setIsLoading(true);
                    const data = MetricId ? await MetricService.EditMetric(MetricId, filteredValues) : await MetricService.addMetric(filteredValues);
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        action.resetForm();
                        const MetricName = await BotService.getAllMetricName();
                        setMetricData(MetricName?.data?.metric);
                        navigate(ROUTING_CONSTANT.SUB_METRIC_VIEW);

                        const { _id, name } = data.data;

                        if (MetricId) {
                            const existingIndex = metricData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setMetricData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, name };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    // const handleAddOption = () => {
    //         const newOption = { what: "", why: "" };
    //         if (values.metricWhatWhy.every((option) => option.what !== "" && option.why !== "")) {
    //             setValues((prevValues) => ({
    //                 ...prevValues,
    //                 metricWhatWhy: [...prevValues.metricWhatWhy, newOption],
    //             }));
    //         } else {
    //             toast.error(CONSTANT.VALID_OPTIONS);
    //         }
    //     };
    
    //     const handleRemoveOption = (index: any) => {
    //         setValues((prevValues) => {
    //             const updatedOptions = [...prevValues.metricWhatWhy];
    //             updatedOptions.splice(index, 1);
    //             return { ...prevValues, metricWhatWhy: updatedOptions };
    //         });
    //     };

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setMetricId(decryptData(params.id));
                getMetricById(decryptData(params.id));
                setFieldsDisabled(true);
            }
        };
        init();
        if (devicesData?.length === 0) {
            getAllDevice();
        }
    }, []);

    const getMetricById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await MetricService.getMetricById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setMetricAllData(response?.data?.metric);
                setValues({ ...response?.data?.metric });
                setSelectedDeviceName(response?.data?.metric?.devices?.deviceName);
                setSelectedDeviceId(response?.data?.metric?.devices?._id);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.SUB_METRIC_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {MetricId ? CONSTANT.UPDATE : CONSTANT.ADD} Metric
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Metric Name*</label>

                                <FormGroup id="metricName">
                                    <Input
                                        placeholder="Metric Name"
                                        className={`${isInvalidForm && !values.metricName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            } ${fieldsDisabled ? "user-input" : ""}`}
                                        name="metricName"
                                        value={values.metricName}
                                        onChange={handleChange}
                                        disabled={fieldsDisabled}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.metricName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Metric User Facing Name*</label>

                                <FormGroup id="metricUserFacingName">
                                    <Input
                                        placeholder="Metric User Facing Name"
                                        className={`${isInvalidForm && !values.metricUserFacingName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="metricUserFacingName"
                                        value={values.metricUserFacingName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.metricUserFacingName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Metric Unit*</label>

                                <FormGroup id="metricUnit">
                                    <Input
                                        placeholder="Metric Unit"
                                        className={`${isInvalidForm && !values.metricUnit
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="metricUnit"
                                        value={values.metricUnit}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.metricUnit && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Field Name*</label>

                                <FormGroup id="fieldName">
                                    <CreatableSelect
                                        value={values.fieldName}
                                        formatOptionLabel={(option: any) => option.label}
                                        onChange={(newValue: any, actionMeta: ActionMeta<OptionType>) => {
                                            if (actionMeta.action === 'remove-value' || actionMeta.action === 'pop-value') {
                                                const deletedOption = actionMeta.removedValue || (newValue && newValue[newValue.length - 1]);

                                                if (deletedOption && deletedOption.__isNew__) {
                                                    const updatedValues = newValue as { label: any; value: any; }[];  
                                                    setFieldValue('fieldName', updatedValues);
                                                } else {
                                                    return;
                                                }
                                            } else {
                                                setFieldValue('fieldName', newValue as { label: any; value: any; }[]);
                                            }
                                        }}
                                        options={[]}
                                        isMulti
                                        isClearable={false}
                                    />
                                </FormGroup>
                                {isInvalidForm && values.fieldName.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIELD_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Data Range (Time Frame)*</label>

                                <FormGroup id="dataRange">
                                    <select
                                        name="dataRange"
                                        value={values.dataRange}
                                        onChange={handleChange}

                                        className={`${isInvalidForm && !values.dataRange
                                            ? "border border-danger form-select form-select-lg"
                                            : "form-select form-select-lg"
                                            }`}
                                    >
                                        <option value="">Select Data Range</option>
                                        <option value="last_7_days_avg">Last 7 Days Average</option>
                                        <option value="last_30_days_avg">Last 30 days avg</option>
                                        <option value="last_60_days_avg">Last 60 days avg</option>
                                        <option value="last_90_days_avg">Last 90 days avg</option>
                                        <option value="latest_date">Latest / Based on Date</option>

                                    </select>
                                </FormGroup>
                                {isInvalidForm && !values.dataRange && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.DATA_RANGE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6" key="device">
                                    <label className="form-labels">Select Device*</label>

                                    <FormGroup id="device">
                                        <Select
                                            styles={customStyles}
                                            onChange={(selectedOption) => {
                                                setSelectedDeviceId(
                                                    selectedOption ? selectedOption.value : ""
                                                );
                                                setSelectedDeviceName(
                                                    selectedOption ? selectedOption.label : ""
                                                );
                                            }}
                                            options={
                                                devicesData &&
                                                devicesData
                                                    .map((device: any) => ({
                                                        value: device._id,
                                                        label: device.deviceName,
                                                    }))
                                            }
                                            placeholder="Select Device"
                                            value={
                                                selectedDeviceName
                                                    ? {
                                                        value: selectedDeviceId,
                                                        label: selectedDeviceName,
                                                    }
                                                    : null
                                            }
                                            isSearchable={true}
                                            className={`${isInvalidForm && !selectedDeviceName
                                                ? "border border-danger"
                                                : ""
                                                }`}
                                        />
                                    </FormGroup>
                                    {isInvalidForm && !selectedDeviceName && (
                                        <p className="text-danger ms-2">
                                            <small>{CONSTANT.DEVICE_ID}</small>
                                        </p>
                                    )}
                                </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Frequency Update*</label>

                                <FormGroup id="frequencyUpdate">
                                    <select
                                        name="frequencyUpdate"
                                        value={values.frequencyUpdate}
                                        onChange={handleChange}

                                        className={`${isInvalidForm && !values.frequencyUpdate
                                            ? "border border-danger form-select form-select-lg"
                                            : "form-select form-select-lg"
                                            }`}
                                    >
                                        <option value="">Select Frequency Update</option>
                                        <option value="daily">Daily</option>
                                        <option value="weekly">Weekly</option>

                                    </select>
                                </FormGroup>
                                {isInvalidForm && !values.frequencyUpdate && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FREQUENCY}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Metric Description</label>

                                <FormGroup id="metricDescription">
                                    <Textarea
                                        rows={5}
                                        placeholder="Metric Description"
                                        className={`${"border   rounded-2  w-100 "}`}
                                        name="metricDescription"
                                        value={values.metricDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                            </div>

                            {/* <div className="col-sm-12 col-md-6">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <><label className="form-labels">Metric What & Why*</label><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddOption}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg></>
                                        </div>
                                    </>
                                    <div>
                                        {values?.metricWhatWhy?.map((option: any, index: any) => (
                                            <div key={index} className="">
                                                <div className="d-flex gap-2 align-items-center justify-content-center mt-1">
                                                    <Input
                                                        placeholder="Metric Why"
                                                        className={`${isInvalidForm && !option.what ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.what}
                                                        name={`metricWhatWhy[${index}].what`}
                                                        onChange={handleChange} />
                                                    <Input
                                                        placeholder="Metric Why"
                                                        className={`${isInvalidForm && !option.why ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.why}
                                                        name={`metricWhatWhy[${index}].why`}
                                                        onChange={handleChange} />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveOption(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                                {isInvalidForm && values.metricWhatWhy.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.OPTION}</small>
                                    </p>
                                )}
                            </div> */}

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Metric What*</label>

                                <FormGroup id="metricWhat">
                                    <Input
                                        placeholder="Metric What"
                                        className={`${isInvalidForm && !values.metricWhat
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="metricWhat"
                                        value={values.metricWhat}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.metricWhat && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>


                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Metric Why*</label>

                                <FormGroup id="metricWhy">
                                    <Input
                                        placeholder="Metric Why"
                                        className={`${isInvalidForm && !values.metricWhy
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="metricWhy"
                                        value={values.metricWhy}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.metricWhy && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>


                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Metric Status*</label>
                                <FormGroup id="metricType">
                                    <select
                                        className={`${isInvalidForm && values.status.length === 0
                                            ? "border border-danger  form-select form-selet-lg input-form"
                                            : "form-select form-selet-lg input-form"
                                            }`}
                                        name="status"
                                        value={values.status}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Metric Status</option>
                                        <option value={CONSTANT.STATUS.ACTIVE}>
                                            {CONSTANT.STATUS.ACTIVE}
                                        </option>
                                        <option value={CONSTANT.STATUS.INACTIVE}>
                                            {CONSTANT.STATUS.INACTIVE}
                                        </option>
                                    </select>
                                </FormGroup>

                                {isInvalidForm && values.status.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.MG_STATUS}</small>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 mt-5 process-btn ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {MetricId ? CONSTANT.UPDATE : CONSTANT.ADD} Metric
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditMetric;
