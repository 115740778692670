import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { VariantsService } from "../../../services/variants.service";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    isValidHttpUrl,
    isValidUrl,
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import { decryptData } from "../../../core/auth.service";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import "../User/user.scss";
import Select from "react-select";
import { UserHardwareSpecificService } from "../../../services/hardwareSpecific.service";

const AddEditVariants = () => {
    const { devicesData, variantsData, setVariantsData, setDevicesData }: any =
        useContext(DataContext);
        console.log('variantsData',variantsData);
    const params = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [productId, setUserSubCategoryId] = useState("");
    const [UserProfileSubCategoryAllData, setUserProfileSubCategoryAllData] = useState({
        color: "",
        productId: "",
        size: "",
        image: "",
    });

    const [selectedCategoryName, setSelectedCategoryName] = useState("");
    const [selectedUserProfileSubCategoryId, setSelectedUserProfileSubCategoryId] =
        useState("");

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const validationSchema = yup.object().shape({
        color: yup.string().required("Name is required"),
    });
    const { values, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...UserProfileSubCategoryAllData },
        validationSchema,
        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const data = productId ? await VariantsService.updateVariantsById(productId, { ...values,productId:selectedUserProfileSubCategoryId  }) : await VariantsService.createVariants({ ...values,productId:selectedUserProfileSubCategoryId });
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    const UserProfileCategoryName = await VariantsService.getAllVariantsName();
                    setVariantsData(UserProfileCategoryName?.data?.Variants);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.VARIANT_VIEW);

                    const { _id, color } = data.data;
                    if (productId) {
                        const existingIndex = variantsData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setVariantsData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, color };
                                return newData;
                            });
                        }
                    }
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.error);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setUserSubCategoryId(decryptData(params.id));
                getVariantsById(decryptData(params.id));
            }
        };
        init();
        if(devicesData?.length === 0){
            getAllDevice();
        }
    }, []);

      const getAllDevice = async () => {
              try {
                  let data: any = await UserHardwareSpecificService.getAllListOfDevice();
                  if (data && data.status === CONSTANT.SUCCESS) {
                      const responseData = data?.data?.device;
                      setDevicesData(responseData);
                  } else {
                      toast.error(data.message);
                  }
              } catch (error: any) {
                  toast.error(error?.response?.data?.message);
              }
          };

    const getVariantsById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await VariantsService.getVariantsById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setUserProfileSubCategoryAllData(response?.data?.Variants);
                setValues({ ...response?.data?.Variants });
                setSelectedCategoryName(
                    response?.data?.Variants?.productId?.deviceName
                );
                setSelectedUserProfileSubCategoryId(
                    response?.data?.Variants?.productId?._id
                );
            }
        } catch (error: any) {
            toast.error(error.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.VARIANT_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    
    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {productId ? CONSTANT.UPDATE : CONSTANT.ADD} Variant
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Select Product*</label>

                                <FormGroup id="categoryName">
                                     <Select
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            setSelectedUserProfileSubCategoryId(
                                                selectedOption ? selectedOption.value : ""
                                            );
                                            setSelectedCategoryName(
                                                selectedOption ? selectedOption.label : ""
                                            );
                                        }}
                                        options={
                                            devicesData &&
                                            devicesData.map((dosageAssistance: any) => ({
                                                value: dosageAssistance._id,
                                                label: dosageAssistance.deviceName,
                                            }))
                                        }
                                        placeholder="Select Product"
                                        value={
                                            selectedCategoryName
                                                ? {
                                                    value: selectedUserProfileSubCategoryId,
                                                    label: selectedCategoryName,
                                                }
                                                : null
                                        }
                                        className={`${isInvalidForm && !selectedCategoryName
                                                ? "border border-danger"
                                                : "border rounded-circle"
                                            }`}
                                        isSearchable={true}
                                    /> 
                                </FormGroup>
                                {isInvalidForm && selectedCategoryName.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.CATEGORY_ID}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Variant Color*</label>

                                <FormGroup id="subcategoryname">
                                    <Input
                                        placeholder="Variant Color"
                                        className={`${isInvalidForm && !values.color
                                                ? "border border-danger input-form"
                                                : "input-form"
                                            }`}
                                        name="color"
                                        value={values.color}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.color && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Variant Size*</label>

                                <FormGroup id="size">
                                    <Input
                                        placeholder="Variant Size"
                                        className={`${isInvalidForm && !values.size
                                                ? "border border-danger input-form"
                                                : "input-form"
                                            }`}
                                        name="size"
                                        value={values.size}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.size && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Variant Image*</label>

                                <FormGroup id="image">
                                    <Input
                                        placeholder="Variant Image"
                                        className={`${isInvalidForm && !values.image
                                                ? "border border-danger input-form"
                                                : "input-form"
                                            }`}
                                        name="image"
                                        value={values.image}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.image && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 mt-5 process-btn ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {productId ? CONSTANT.UPDATE : CONSTANT.ADD} Variant
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditVariants;
